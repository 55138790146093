import Home from "./pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Settings from "./pages/settings/settings";
import Confirmation from "./pages/confirmation/confirmation";
import Login from "./pages/login/login";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import EditProfile from "./components/EditProfile/EditProfile";
import ChangeProfile from "./components/ChangeProfile/ChangeProfile";
import ChangeEmail from "./components/ChangeEmail/ChangeEmail";
import EditEmail from "./components/EditEmail/EditEmail";
import ConfirmOTP from "./components/ConfirmOTP/ConfirmOTP";
import CreateATab from "./components/CreateATab/CreateATab";
import DeleteAccount from "./components/DeleteAccount/DeleteAccount";
import Flagged from "./components/Flagged/Flagged";
import Liked from "./components/Liked/Liked";
import Saved from "./components/Saved/Saved";
import PrivateTabs from "./components/PrivateTabs/PrivateTabs";
import Logout from "./components/Logout/Logout";
import PersonalTopics from "./components/PersonalTopics/PersonalTopics";
import SubscriptionHistory from "./components/SubscriptionHistory/SubscriptionHistory";
import ViewSub from "./components/ViewSub/ViewSub";
import { PriContextProvider } from "./components/PrivateTabs/State";
import { PerContextProvider } from "./components/PersonalTopics/State";
import { FgContextProvider } from "./components/Flagged/State";
import { LkContextProvider } from "./components/Liked/State";
import { SvContextProvider } from "./components/Saved/State";
import TimeLine from "./components/TimeLine/TimeLine";
import Public from "./pages/Public/Public";
import TabPagePublic from "./components/TabPage/TabPagePublic";
import { useLocation } from "react-router-dom";
import TopicPagePublic from "./components/TopicPage/TopicPagePublic";
import ListParticipation from "./components/TabPage/ListParticipation";
import Forum from "./components/Forum/Forum";
import { ForumContextProvider } from "./components/Forum/State";
import ForumDefault from "./components/ForumDefault/ForumDefault";
import { DefaultForumContextProvider } from "./components/ForumDefault/State";

const Target = () => {
  const location = useLocation();
  if (
    location.pathname.split("/")[1] === "tab" ||
    location.pathname.split("/")[1] === "tabs"
  ) {
    return (
      <Routes>
        <Route path="/tab/:currentTabId" element={<TabPagePublic />} />
        <Route path="/tabs/:currentTabId" element={<TabPagePublic />} />
        <Route path="/login" element={<Login />} />
        <Route path="/tab/*" element={<ErrorPage />} />
        <Route path="/tabs/*" element={<ErrorPage />} />
      </Routes>
    );
  } else if (
    location.pathname.split("/")[1] === "topics" ||
    location.pathname.split("/")[1] === "topic" ||
    location.pathname.split("/")[1] === "post" ||
    location.pathname.split("/")[1] === "posts"
  ) {
    return (
      <Routes>
        <Route path="/topics/:topicId/:tabId" element={<TopicPagePublic />} />
        <Route path="/topic/:topicId/:tabId" element={<TopicPagePublic />} />
        <Route path="/posts/:topicId/:tabId" element={<TopicPagePublic />} />
        <Route path="/post/:topicId/:tabId" element={<TopicPagePublic />} />
        <Route path="/login" element={<Login />} />
        <Route path="/topics/*" element={<ErrorPage />} />
        <Route path="/topic/*" element={<ErrorPage />} />
        <Route path="/posts/*" element={<ErrorPage />} />
        <Route path="/post/*" element={<ErrorPage />} />
      </Routes>
    );
  } else if (
    location.pathname.split("/")[1] === "list" ||
    location.pathname.split("/")[1] === "lists"
  ) {
    return (
      <Routes>
        <Route path="/list/:token" element={<ListParticipation />} />
        <Route path="/lists/:token" element={<ListParticipation />} />
        <Route path="/tab/:currentTabId" element={<TabPagePublic />} />
        <Route path="/tabs/:currentTabId" element={<TabPagePublic />} />
        <Route path="/login" element={<Login />} />
        <Route path="/list/*" element={<ErrorPage />} />
        <Route path="/lists/*" element={<ErrorPage />} />
      </Routes>
    );
  } else if (
    location.pathname.split("/")[1] === "forum" ||
    location.pathname.split("/")[1] === "forums"
  ) {
    return (
      <Routes>
        <Route
          path="/forum/:token"
          element={
            <ForumContextProvider>
              <Forum />
            </ForumContextProvider>
          }
        />
        <Route
          path="/forums/:token"
          element={
            <ForumContextProvider>
              <Forum />
            </ForumContextProvider>
          }
        />
        <Route path="/list/:token" element={<ListParticipation />} />
        <Route path="/lists/:token" element={<ListParticipation />} />
        <Route path="/tab/:currentTabId" element={<TabPagePublic />} />
        <Route path="/tabs/:currentTabId" element={<TabPagePublic />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/forum/*"
          element={
            <DefaultForumContextProvider>
              <ForumDefault />
            </DefaultForumContextProvider>
          }
        />
        <Route
          path="/forums/*"
          element={
            <DefaultForumContextProvider>
              <ForumDefault />
            </DefaultForumContextProvider>
          }
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />}>
          <Route index element={<TimeLine />} />
          <Route
            path="private"
            element={
              <PriContextProvider>
                <PrivateTabs />
              </PriContextProvider>
            }
          />
          <Route path="createtab" element={<CreateATab />} />
          <Route
            path="personal"
            element={
              <PerContextProvider>
                <PersonalTopics />
              </PerContextProvider>
            }
          />
          <Route
            path="liked"
            element={
              <LkContextProvider>
                <Liked />
              </LkContextProvider>
            }
          />
          <Route
            path="flagged"
            element={
              <FgContextProvider>
                <Flagged />
              </FgContextProvider>
            }
          />
          <Route
            path="saved"
            element={
              <SvContextProvider>
                <Saved />
              </SvContextProvider>
            }
          />
          <Route path="subscriptions" element={<SubscriptionHistory />} />
        </Route>
        <Route path="/settings" element={<Settings />}>
          <Route
            index
            element={
              <>
                <EditProfile />
                <EditEmail />
                <ViewSub />
                <Logout />
                <DeleteAccount />
              </>
            }
          />
          <Route
            path="setprofile"
            element={
              <>
                <ChangeProfile />
                <EditEmail />
              </>
            }
          />
          <Route
            path="editemail"
            element={
              <>
                <EditProfile />
                <ChangeEmail />
              </>
            }
          />
          <Route path="confirmotp" element={<ConfirmOTP />} />
          <Route path="subscriptions" element={<SubscriptionHistory />} />
        </Route>

        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/public" element={<Public />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
  }
};

function MainRoutes() {
  return (
    <BrowserRouter>
      <Target />
    </BrowserRouter>
  );
}

export default MainRoutes;
