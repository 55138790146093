import { useContext } from "react";

import Topics from "../Topics/Topics";
import { PriContext } from "./State";
import { GetTopics } from "../../utils/Effects";

function PrivateTabs() {
  const { priState, priDispatch } = useContext(PriContext);
  const { load, newTopicStatus } = priState;

  //Fetch data as component loads
  const topicUrl = `/private?cntr=${load}`;
  GetTopics(load, priDispatch, topicUrl, newTopicStatus);

  return (
    <Topics title="pri" localState={priState} localDispatch={priDispatch} />
  );
}

export default PrivateTabs;
