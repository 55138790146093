import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Context } from "../../utils/Context";
import { useNavigate } from "react-router-dom";

import axios from "../../utils/Axios";
import "./ConfirmOTP.scss";

function ConfirmOTP() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["components", "extras"]);
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const handleChangeEmail = async ({ psk, psw }) => {
    try {
      const { data } = await axios.get(`/rfch?psk=${psk}&psw=${psw}`, config);
      if (data.status === true) {
        enqueueSnackbar(data.msg, { variant: "success" });
        localStorage.setItem("tkn", data?.new_tkn);
        // dispatch({ type: "EMAIL_CHANGE_ALERT" });
        navigate("/settings");
      } else {
        enqueueSnackbar(data.msg, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="otContainer">
      <form onSubmit={handleSubmit(handleChangeEmail)}>
        <input
          type="password"
          placeholder={t("components:enterOTP")}
          id="psk"
          {...register("psk", {
            required: true,
          })}
        />
        <p className="cpError">
          {errors.psk ? t("components:errOTPRequired") : ""}
        </p>

        <input
          type="password"
          placeholder={t("components:enterCurrentPass")}
          id="psw"
          {...register("psw", {
            required: true,
          })}
        />
        <p className="cpError">
          {errors.psw ? t("components:errPassRequired") : ""}
        </p>

        <div className="otBtn">
          <button type="submit">{t("extras:btnSave")}</button>
        </div>
      </form>
    </div>
  );
}

export default ConfirmOTP;
