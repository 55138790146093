import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  StyledSnackbarProvider,
  SnackbarCloseButton,
} from "./components/SnackbarProvider/SnackbarProvider";
import { ContextProvider } from "./utils/Context";
import { TCContextProvider } from "./components/TopicComment/State";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledSnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    hideIconVariant
    autoHideDuration={10000}
    action={(snackbarId) => <SnackbarCloseButton snackbarId={snackbarId} />}
  >
    <ContextProvider>
      <TCContextProvider>
        <App />
      </TCContextProvider>
    </ContextProvider>
  </StyledSnackbarProvider>
);
