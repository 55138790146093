import { useState } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";

function ImageSlider({ slides }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideStyles = {
    maxHeight: "95%",
    maxWidth: "95%",
  };

  const sliderStyles = {
    position: "relative",
    Width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
    objectFit: "contain",
  };

  const leftArrowStyles = {
    position: "absolute",
    top: "47%",
    left: "0",
  };
  const rightArrowStyles = {
    position: "absolute",
    top: "47%",
    right: "0",
  };

  const sliderCounter = {
    position: "absolute",
    top: -20,
    left: 0,
    fontSize: "0.8rem",
  };

  const iconBtnStyle = {
    backgroundColor: "#eee",
    height: "35px",
    width: "35px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  return (
    <div style={sliderStyles}>
      <div style={sliderCounter}>
        {currentIndex + 1}/{slides.length}
      </div>
      <div style={leftArrowStyles}>
        <IconButton onClick={goToPrevious} sx={iconBtnStyle}>
          <ChevronLeftIcon style={{ fontSize: "35px" }} />
        </IconButton>
      </div>
      <div style={rightArrowStyles}>
        <IconButton onClick={goToNext} sx={iconBtnStyle}>
          <ChevronRightIcon style={{ fontSize: "35px" }} />
        </IconButton>
      </div>
      <img
        style={slideStyles}
        src={`https://storage.googleapis.com/trutab/imgs/${slides[currentIndex]}`}
        alt=""
      />
    </div>
  );
}

export default ImageSlider;
