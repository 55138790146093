import { useContext } from "react";

import Topics from "../Topics/Topics";
import { AnonContext } from "./State";
import { GetTopics } from "../../utils/Effects";

function AnonymousTabs() {
  const { anonState, anonDispatch } = useContext(AnonContext);
  const { load, newTopicStatus } = anonState;

  //Fetch data as component loads
  const topicUrl = `/load?cntr=${load}`;
  GetTopics(load, anonDispatch, topicUrl, newTopicStatus);

  return (
    <Topics title="anon" localState={anonState} localDispatch={anonDispatch} />
  );
}

export default AnonymousTabs;
