import { useContext, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import axios from "../../utils/Axios";
import Divider from "../Divider/Divider";
import { AnonContext } from "../AnonymousTabs/State";
import { PubContext } from "../PublicTabs/State";
import { PriContext } from "../PrivateTabs/State";
import { PerContext } from "../PersonalTopics/State";
import { FgContext } from "../Flagged/State";
import { LkContext } from "../Liked/State";
import { SvContext } from "../Saved/State";
import { useTranslation } from "react-i18next";
import { isUserLoggedIn, useScrollToSection } from "../../utils/Effects";
import { Switch, ThemeProvider } from "@mui/material";
import "./NewPostBox.scss";
import { TooltipTheme, switchTheme } from "../../utils/MuiThemes";
import { CircularProgress, Tooltip } from "@mui/material";
import { DefaultForumContext } from "../ForumDefault/State";
import { Context } from "../../utils/Context";
import { useLocation } from "react-router-dom";

const NewPostBox = ({ topicTable, lDispatch, isTabDirector }) => {
  const [image, setImage] = useState([]);
  const [newTopicText, setNewTopicText] = useState("");
  const [topicCat, setTopicCat] = useState(false);
  const [loading, setLoading] = useState(false);
  const dragImage = useRef(null);
  const dragOverImage = useRef(null);
  const tpTopicBoxRef = useRef(null);
  const editInx = "0";
  const [pollOptions, setPollOptions] = useState(["", "", "", ""]);
  const [pollDuration, setPollDuration] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["components", "extras"]);
  const { anonDispatch } = useContext(AnonContext);
  const { pubDispatch } = useContext(PubContext);
  const { priDispatch } = useContext(PriContext);
  const { perDispatch } = useContext(PerContext);
  const { fgDispatch } = useContext(FgContext);
  const { lkDispatch } = useContext(LkContext);
  const { svDispatch } = useContext(SvContext);
  const { defaultForumDispatch } = useContext(DefaultForumContext);

  const { state, dispatch } = useContext(Context);

  const { creatingPoll } = state;
  const tkn = localStorage.getItem("tkn");
  const location = useLocation();

  useScrollToSection(tpTopicBoxRef, "center");

  const handleImageChange = (e) => {
    const images = e.target.files;
    let imageArr = [];
    if (images.length > 5) {
      setImage([]);
      enqueueSnackbar("maximum of 5 files can be uploaded", {
        variant: "error",
      });
    } else {
      for (let i = 0; i <= images.length; i++) {
        imageArr.push(images[i]);
      }
      setImage(imageArr);
    }
  };
  const handleImageSort = () => {
    let _images = [...image];
    const draggedImage = _images.splice(dragImage.current, 1)[0];
    _images.splice(dragOverImage.current, 0, draggedImage);
    dragImage.current = null;
    dragOverImage.current = null;
    setImage(_images);
  };

  const handlePollOptionChange = (index, value) => {
    const updatedOptions = [...pollOptions];
    updatedOptions[index] = value;
    setPollOptions(updatedOptions);
  };

  const hideTopicSetting = () => {
    if (typeof lDispatch === "function") {
      lDispatch({ type: "HIDE_TOPIC" });
    }
  };

  const handleEditPost = async (e) => {
    e.preventDefault();
    if (newTopicText === "") {
      enqueueSnackbar("Empty post not allowed!", { variant: "error" });
      return;
    }
    const userStatus = isUserLoggedIn();
    if (!userStatus && location.pathname.split("/")[1] === "forums") {
      dispatch({ type: "OPEN_AUTH_MODAL" });
      return;
    }

    let postText = newTopicText;
    if (creatingPoll) {
      const pollText = `${pollOptions
        .filter((option) => option !== "")
        .join(" $$ ")}`;
      postText = `${newTopicText} $$ ${pollText}`;
    }
    const data = new FormData();
    data.append("text", postText);
    data.append("tab", topicTable);
    data.append("cat", topicCat ? "public" : "private");
    data.append("edit", editInx);

    // it is ==== newTopicText if not working

    if (creatingPoll) {
      const duration = `${pollDuration.days} ${
        parseInt(pollDuration.days) > 1 ? "days" : "day"
      } ${pollDuration.hours} ${
        parseInt(pollDuration.hours) > 1 ? "hours" : "hour"
      } ${pollDuration.minutes} ${
        parseInt(pollDuration.minutes) > 1 ? "minutes" : "minute"
      }`;
      data.append("duration", duration);
    }
    for (let i = 0; i < image.length; i++) {
      data.append("attch", image[i]);
    }

    const url = "/addt";
    const options = {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
        authorization: `${tkn}`,
      },
      data,
      url,
    };
    try {
      setLoading(true);
      const { data } = await axios(options);
      setLoading(false);
      if (data?.status === false) {
        enqueueSnackbar(data?.msg, { variant: "error" });
        return;
      }
      if (typeof lDispatch === "function") {
        lDispatch({
          type: "ADD_NEW_TOPIC",
          payload: {
            name: data?.new_cmnt.content,
            id: data?.new_cmnt.comnt_id,
          },
        });
      }
      if (typeof lDispatch === "function") {
        lDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }
      hideTopicSetting();

      if (typeof anonDispatch === "function") {
        anonDispatch({
          type: "ADD_NEW_TOPIC",
          payload: data?.new_cmnt,
        });
      }

      if (typeof pubDispatch === "function") {
        pubDispatch({
          type: "ADD_NEW_TOPIC",
          payload: data?.new_cmnt,
        });
      }

      if (typeof priDispatch === "function") {
        priDispatch({
          type: "ADD_NEW_TOPIC",
          payload: data?.new_cmnt,
        });
      }

      if (typeof perDispatch === "function") {
        perDispatch({
          type: "ADD_NEW_TOPIC",
          payload: data?.new_cmnt,
        });
      }

      if (typeof defaultForumDispatch === "function") {
        defaultForumDispatch({
          type: "ADD_NEW_TOPIC",
          payload: data?.new_cmnt,
        });

        defaultForumDispatch({
          type: "HIDE_TOPIC",
        });
      }

      if (typeof fgDispatch === "function" && editInx !== "0") {
        fgDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof lkDispatch === "function" && editInx !== "0") {
        lkDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof svDispatch === "function" && editInx !== "0") {
        svDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const dispatchCreatingPoll = () => {
    dispatch({
      type: "IS_CREATING_POLL",
      payload: creatingPoll === false ? true : false, // Coerce value to a boolean (true or false)
    });
  };

  const durations = ["days", "hours", "minutes"];

  return (
    <form className="npbForm" onSubmit={handleEditPost} ref={tpTopicBoxRef}>
      <ThemeProvider theme={TooltipTheme}>
        <p className="npbTitle">New Post</p>

        <div>
          <textarea
            placeholder="Type new anon post here..."
            className="npbTextArea"
            onChange={(e) => setNewTopicText(e.target.value)}
          />

          <section className="pollsSection">
            <div className="">
              <Divider width="100%" color="#908fc6" />
            </div>
            {creatingPoll && (
              <div className="pollBox">
                <div className="poll_dur_title">Create Poll with new Post</div>

                <div className="poll-container">
                  {pollOptions.map((option, index) => (
                    <div key={index} className="poll_inner">
                      <input
                        id={`choice-${index}`}
                        value={option}
                        placeholder={
                          index === 2 || index === 3
                            ? `Choice ${index + 1} (Optional)`
                            : `Choice ${index + 1}`
                        }
                        className="poll_input"
                        onChange={(e) =>
                          handlePollOptionChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>

                <div className="pollDuration">
                  <p className="poll_dur_title">Set Duration</p>
                  <div className="poll_time_dur">
                    {durations.map((duration, index) => (
                      <div key={index} className="poll_time">
                        <label>{duration}</label>
                        <input
                          className="poll_inp"
                          value={pollDuration[duration] || 0}
                          type="number"
                          max={
                            duration === "days"
                              ? 7
                              : duration === "hours"
                              ? 12
                              : 59
                          }
                          min={0}
                          onChange={(e) =>
                            setPollDuration((prev) => ({
                              ...prev,
                              [duration]: e.target.value,
                            }))
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <p className="pollDurationNote">Max 7 days</p>
                </div>
              </div>
            )}
            {creatingPoll && <Divider />}

            <section className="npbActionContainer">
              <div className="npbPicknCancel">
                <div className="npbPickPhotoContainer">
                  <label htmlFor="attchxx">
                    <Tooltip title="Media" arrow>
                      <img src="/assets/images/pickPhoto.png" alt="" />
                    </Tooltip>
                  </label>
                  <input
                    id="attchxx"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                    multiple
                    onChange={(e) => {
                      handleImageChange(e);
                    }}
                    className="npbFile"
                  />
                </div>
                <Tooltip title="Polls" arrow>
                  <img
                    src="/assets/images/polls.png"
                    alt=""
                    onClick={dispatchCreatingPoll}
                  />
                </Tooltip>
              </div>
              <div className="npbSwitchnBtn">
                <Tooltip
                  title="Public posts can be made by Tab's directors"
                  arrow
                >
                  <div className="npbSwitchContainer">
                    <label
                      style={{
                        color: topicCat ? "#572e91" : "#5b5b5b",
                        marginBottom: "-2px",
                      }}
                    >
                      {topicCat ? "Public" : "Private"}
                    </label>
                    <ThemeProvider theme={switchTheme}>
                      <Switch
                        size="small"
                        sx={{
                          cursor: "pointer",
                          height: 20,
                          "& .MuiSwitch-thumb": {
                            height: 12.5, // Customize thumb height
                            width: 12.5, // Customize thumb width
                          },
                          "& .MuiSwitch-track": {
                            height: 20, // Customize track height
                            width: 36,
                          },
                        }}
                        checked={topicCat}
                        disabled={!isTabDirector}
                        onChange={(e) => setTopicCat(e.target.checked)}
                      />
                    </ThemeProvider>
                  </div>
                </Tooltip>
                <button className="npbBtn" disabled={loading}>
                  {loading ? (
                    <>
                      {t("extras:btnPosting")}
                      <CircularProgress size={15} className="btnProgress" />
                    </>
                  ) : (
                    t("extras:btnPost")
                  )}
                </button>
              </div>
            </section>
          </section>
          <div className="">
            <Divider width="100%" color="#908fc6" />
          </div>
        </div>

        {image.length >= 1 && image.length <= 5 && (
          <div className="npbPreviewContainer">
            <div className="npbPreviewInfo">{t("components:attachHint")}</div>

            {image.length > 2 && image.length <= 5 && (
              <>
                <div className="npbDivider">
                  <Divider />
                </div>
                <div className="npbPreviewInfo">
                  {t("components:dragOrder")}
                </div>
              </>
            )}
            <div className="npbPreviewNameContainer">
              {image.map((image, index) => {
                return (
                  <div
                    key={index}
                    draggable
                    className="npbDrag"
                    onDragStart={() => (dragImage.current = index)}
                    onDragEnter={() => (dragOverImage.current = index)}
                    onDragEnd={handleImageSort}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <Divider />
                    <div className="npbPreviewName">{image?.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </ThemeProvider>
    </form>
  );
};

export default NewPostBox;
