import React, { useContext, useEffect, useState } from "react";
import axios from "../../utils/Axios"; // adjust path as needed

import "./PollPage.scss";
import { useSnackbar } from "notistack";
import { Context } from "../../utils/Context";
import {
  addTimeToTimestamp,
  getTimeDifference,
} from "../../utils/TimeFormatter";

const PollPage = ({ data, topicData, pollData, setPollData }) => {
  const postId = topicData?.comnt_id;
  const token = localStorage.getItem("tkn");
  const { dispatch } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  const [poll, setPoll] = useState({
    answers: [],
    answersWeight: [],
    selectedAnswer: -1,
    pollcount: pollData?.total,
  });
  const [loading, setLoading] = useState(true);
  const [voted, setVoted] = useState(false);

  useEffect(() => {
    const formatPollData = (data) => {
      const parts = data.split("$$");
      const answers = parts.slice(1);

      setPoll({
        answers,
        answersWeight: pollData?.percent,
        selectedAnswer: -1,
        pollcount: pollData?.total,
      });
      setLoading(false);
    };

    formatPollData(data);
  }, [data, pollData]);

  const showResult =
    voted ||
    getTimeDifference(
      addTimeToTimestamp(topicData?.time, topicData?.poll_dur)
    ) === "Voting ended";

  const votePoll = async (e, pollOption) => {
    e.stopPropagation();

    if (showResult) {
      return;
    }
    try {
      const response = await axios.get(`/polls?p=${postId}&c=${pollOption}`, {
        headers: {
          authorization: `${token}`,
        },
      });

      if (
        response.data.status &&
        response.data.msg?.toLowerCase()?.includes("vote")
      ) {
        setPollData(response?.data);
        dispatch({ type: "VOTE_ALERT" });
        enqueueSnackbar(response?.data?.msg, { variant: "success" });
      } else {
        enqueueSnackbar(response?.data?.msg, { variant: "error" });
      }
      setVoted(true);
    } catch (error) {
      console.error("Error voting:", error);
    }
  };

  if (loading) return <div>Loading poll data...</div>;

  return (
    <section>
      {topicData?.poll_dur !== null && (
        <div className="pollContainer">
          <div className="poll">
            <div className={`answers ${showResult ? "selected" : ""}`}>
              {poll.answers
                ?.filter((answer) => answer.trim() !== "")
                ?.map((answer, index) => (
                  <div
                    key={index}
                    className={`answer ${
                      poll.selectedAnswer === index ? "selected" : ""
                    }`}
                    onClick={(e) => votePoll(e, index)}
                    style={{
                      pointerEvents:
                        poll.selectedAnswer !== -1 ? "none" : "auto",
                      zIndex: 0,
                    }}
                  >
                    <span className="poll_cont"> {answer}</span>

                    <span
                      className="percentage-bar"
                      style={{
                        width: `${
                          showResult
                            ? pollData?.percent?.[index]
                                ?.split(".")?.[0]
                                ?.replace("%", "")
                            : "0"
                        }%`,
                      }}
                    ></span>
                    {showResult && (
                      <span className="percentage-value">
                        {pollData?.percent?.[index]
                          ?.split(".")?.[0]
                          ?.replace("%", "") || "0"}
                        %
                      </span>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PollPage;
