import { useContext } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./EditProfile.scss";
import Avatar from "../Avatar/Avatar";
import { Context } from "../../utils/Context";
import { GetUserTabs } from "../../utils/Effects";

function EditProfile() {
  const { t } = useTranslation(["components", "extras"]);
  const navigate = useNavigate();

  const { state } = useContext(Context);
  const { userInfo } = state;

  GetUserTabs();

  return (
    <div className="epContainer">
      <div className="epEditRow">
        <div className="epAvatar">
          <Avatar />
          <div className="epEditFill">
            <p className="epSmall">{t("components:personal")}</p>
            {userInfo && <p className="epName">{userInfo?.name}</p>}
            <div className="epSubContainer">
              <div className="epEditRow">
                <div className="epEditFill">
                  <p className="epSmall">{t("components:yourPass")}</p>
                  <p className="epSmall">***************</p>
                </div>
              </div>
              <p className="epSmall">
                {t("components:lastEditedOn")} 20/09/2021
              </p>
            </div>
          </div>
        </div>
        <div>
          <button onClick={() => navigate("/settings/setprofile")}>
            {t("extras:btnEdit")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
