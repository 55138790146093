import { useContext } from "react";

import Topics from "../Topics/Topics";
import { FgContext } from "./State";
import { GetTopics } from "../../utils/Effects";

function Flagged() {
  const { fgState, fgDispatch } = useContext(FgContext);
  const { load, newTopicStatus } = fgState;

  //Fetch data as component loads
  const topicUrl = `/flagged?indx=${load}`;
  GetTopics(load, fgDispatch, topicUrl, newTopicStatus);

  return <Topics title="fg" localState={fgState} localDispatch={fgDispatch} />;
}

export default Flagged;
