import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"],
    },
    backend: {
      loadPath: "/locales/i18n/{{ns}}/{{lng}}.json",
    },
    fallbackLng: "en-US",
    debug: false,
    ns: ["components", "extras"],

    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ",",
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
