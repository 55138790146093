import { createContext, useReducer } from "react";

export const FgContext = createContext("");

export const initialState = {
  showSetting: null,
  showComment: null,
  parent: null,
  newComment: null,
  topicData: [],
  posting: false,
  offset: null,
  load: 0,
  loading: false,
  open: false,
  showModal: null,
};

export function reducer(state, action) {
  switch (action.type) {
    case "SET_LOAD":
      if (state.offset !== null || undefined) {
        return { ...state, load: state.offset };
      }

    case "SHOW_SETTING":
      return { ...state, showSetting: action.payload };
    case "SHOW_COMMENT":
      return { ...state, showComment: action.payload };
    case "PARENT":
      return { ...state, parent: action.payload };
    case "NEW_COMMENT":
      const data = action.payload;
      if (data?.status === true) {
        return { ...state, newComment: data?.new_c };
      }

    case "POSTING":
      return { ...state, posting: true };
    case "POSTING_DONE":
      return { ...state, posting: false };
    case "UPDATE_COMMENT":
      const pId = action.payload.pId;
      const newCount = action.payload.newCount;
      const toUpdate = state.topicData?.find((td) => td?.comnt_id === pId);
      if (toUpdate?.children !== undefined) {
        toUpdate.children = newCount;
        return { ...state };
      }
      return { ...state };

    case "UPDATE_TOPIC_STATUS":
      const tId = action.payload?.tId;
      const newStatus = action.payload;
      const statusUpdate = state.topicData?.find((td) => td?.comnt_id === tId);
      if (statusUpdate?.settings !== undefined) {
        statusUpdate.settings[0] = newStatus?.status?.public;
        statusUpdate.edited = newStatus?.status?.edited;
        statusUpdate.content = newStatus?.content;
        return { ...state };
      }
      return { ...state };
    case "SET_TOPIC_DATA":
      const incomingTopics = action.payload.topics;
      const newOffset = action.payload.offset;
      const newTopics = incomingTopics?.filter((tt) =>
        state.topicData.every((td) => td?.comnt_id !== tt?.comnt_id)
      );
      if (newTopics !== undefined) {
        return {
          ...state,
          topicData: [...state.topicData, ...newTopics],
          offset: newOffset,
        };
      }

    case "SHOW_MODAL":
      return { ...state, showModal: action.payload };
    case "OPEN_MODAL":
      return { ...state, open: true };
    case "CLOSE_MODAL":
      return { ...state, open: false };
    case "DELETE_TOPIC":
      const fData = state.topicData.filter(
        (filtered) => filtered.comnt_id !== action.payload
      );
      return { ...state, topicData: fData };
    case "TAB_SETTINGS_DONE":
      const fName = action.payload.fName;
      const cName = action.payload.cName;

      const targetTopic = state.topicData.filter(
        (topic) => topic.parent === fName
      );
      if (targetTopic !== undefined) {
        targetTopic.every((topic) => (topic.parent = cName));
        return {
          ...state,
        };
      }

    default:
      return state;
  }
}

export function FgContextProvider(props) {
  const [fgState, fgDispatch] = useReducer(reducer, initialState);
  const value = { fgState, fgDispatch };
  return (
    <FgContext.Provider value={value}>{props.children}</FgContext.Provider>
  );
}
