import { useContext, useState } from "react";

import qs from "qs";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress, Dialog, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import "./DeleteAccount.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { commentDialogTheme } from "../../utils/MuiThemes";
import { ThemeProvider } from "@emotion/react";

function DeleteAccount() {
  const [showModal, setShowModal] = useState(false);
  const [showLastModal, setShowLastModal] = useState(false);
  const [startCheckPass, setStartCheckPass] = useState(false);
  const [startDeleteAcc, setStartDeleteAcc] = useState(false);

  const { t } = useTranslation(["components", "extras"]);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();

  const { state, dispatch } = useContext(Context);
  const { userInfo } = state;
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const handleLastModal = () => {
    setShowLastModal(false);
    setShowModal(true);
  };

  const handleModal = () => {
    setShowModal(false);
  };

  const hideAllModal = () => {
    setShowLastModal(false);
    setShowModal(false);
  };

  const handleConfirmPassword = async ({ psswrd }) => {
    const url = "/confpw";
    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({ pass: psswrd }),
      url,
    };
    try {
      setStartCheckPass(true);
      const { data } = await axios(options);
      setStartCheckPass(false);
      if (data.status === false) {
        enqueueSnackbar(data.msg, { variant: "error" });
      } else {
        handleLastModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteAccount = async () => {
    setStartDeleteAcc(true);
    const { data } = await axios.post(
      "/delc",
      {
        id: `${userInfo?.idd}`,
        type: "u",
      },
      config
    );
    setStartDeleteAcc(false);
    if (data?.status === true) {
      enqueueSnackbar(data?.msg, { variant: "success" });
      hideAllModal();
      dispatch({ type: "USER_LOGOUT" });
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      enqueueSnackbar(data?.msg, { variant: "error" });
    }
  };

  return (
    <div className="daContainer">
      <div className="daSubContainer">
        <div
          className="daIconText"
          onClick={() => {
            setShowLastModal(true);
          }}
        >
          <DeleteIcon className="daIcon" /> {t("components:deleteAcc")}
        </div>
      </div>
      <ThemeProvider theme={commentDialogTheme}>
        <Dialog
          open={showModal}
          onClose={hideAllModal}
          maxWidth="xs"
          PaperProps={{
            style: {
              boxShadow: "none",
            },
          }}
        >
          <DialogActions>
            <div className="daModalCont">
              <h4>{t("components:deleteAcc")}</h4>
              <p>{t("components:sure")}</p>
              <p>{t("components:delAccCost")}</p>
              <p>{t("components:delFinalWarn")}</p>
              <div className="btn">
                <button onClick={handleModal} className="btnCancel">
                  {t("extras:btnCancel")}
                </button>
                <button onClick={handleDeleteAccount} className="btnExecute">
                  {t("extras:btnDelete")}
                  {startDeleteAcc && (
                    <CircularProgress size={15} className="daBtnProgress" />
                  )}
                </button>
              </div>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showLastModal}
          onClose={hideAllModal}
          maxWidth="xs"
          PaperProps={{
            style: {
              boxShadow: "none",
            },
          }}
        >
          <DialogActions>
            <form
              onSubmit={handleSubmit(handleConfirmPassword)}
              className="daForm"
            >
              <p>{t("components:descDelAcc")}</p>
              <div>
                <input
                  {...register("psswrd", { required: true })}
                  type="password"
                  placeholder={t("extras:enterPass")}
                  className="daInput"
                />
                <button type="submit" className="daButton">
                  {t("extras:btnContinue")}
                  {startCheckPass && (
                    <CircularProgress size={15} className="daBtnProgress" />
                  )}
                </button>
              </div>
            </form>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}

export default DeleteAccount;
