export const tipsData = [
  {
    title: "Tabs you created or control",
    pointer: "",
    desc: "As admin, you own these Tabs, and can modify their profiles & settings.",
  },
  {
    title: "Your quota size for Tabs you own",
    pointer: "",
    desc: "Enable more participants to join the Tabs that you control.",
  },
  {
    title: "Tabs where you participate as a...",
    pointer: <img src="/assets/images/dfPointer.svg" alt="" />,
    desc: "...member or director, with access to all the ongoing activities on the Tab.",
  },
];
