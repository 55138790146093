import { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

import "./SearchResult.scss";
import { Context } from "../../utils/Context";
import { CircularProgress } from "@mui/material";
import Divider from "../Divider/Divider";
import { useLocation, useNavigate } from "react-router-dom";

function SearchResult() {
  const { t } = useTranslation(["components"]);
  const location = useLocation();
  const navigate = useNavigate();

  const { state, dispatch } = useContext(Context);
  const { searchResult, searchWord, searchProcess } = state;

  //Show Tab Page
  const showTabPage = (result) => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: result?.name });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: result?.tab_id });
    dispatch({
      type: "ON_PAGE",
      payload: "tab",
    });
  };

  const handleOnClickSearchResult = (result) => {
    dispatch({
      type: "SET_PREVIOUS_PAGE",
      payload: location.pathname.split("/")[1],
    });
    location.pathname.split("/")[1] === "tabs" ||
    location.pathname.split("/")[1] === "topics" ||
    location.pathname.split("/")[1] === "posts" ||
    location.pathname.split("/")[1] === "lists" ||
    location.pathname.split("/")[1] === "forums"
      ? navigate(`/tabs/${result?.tab_id}`)
      : showTabPage(result);
  };

  useEffect(() => {
    if (searchWord?.length < 3) {
      dispatch({ type: "CLEAR_SEARCH_RESULT" });
    }
    if (searchWord?.length > 0) {
      dispatch({ type: "START_SEARCH" });
    }
  }, [searchWord]);

  return (
    <>
      {(searchWord?.length > 0 || searchProcess === true) && (
        <>
          <div>
            {searchProcess ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <CircularProgress size={20} />
              </div>
            ) : (
              searchResult &&
              searchProcess === false && (
                <p className="title">
                  {searchResult.length === 0 && searchProcess === false
                    ? "No result found"
                    : t("searchResults")}
                </p>
              )
            )}
          </div>
          <div className="searchResult" style={{opacity:searchResult?.length===0?0:1}}>
              {searchResult?.map((result) => {
                return (
                  <div className="resultCont" key={result}>
                    <button onClick={() => handleOnClickSearchResult(result)}>
                      @
                      {result?.name?.length >= 40
                        ? result?.name?.slice(0, 40) + "..."
                        : result?.name}
                      <br />
                      <span>
                        {result?.title?.length >= 40
                          ? result?.title?.slice(0, 40) + "..."
                          : result?.title}
                      </span>
                    </button>
                    <Divider />
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );
}

export default SearchResult;
