import { useContext } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./EditEmail.scss";
import { Context } from "../../utils/Context";

function EditEmail() {
  const { t } = useTranslation(["components", "extras"]);
  const navigate = useNavigate();

  const { state } = useContext(Context);
  const { userInfo } = state;

  return (
    <div className="eeContainer">
      <div className="eeSubContainer">
        <div className="eeTextContainer">
          <p>{t("components:EMAIL")}</p>
          <p>{userInfo?.email}</p>
          <p>{t("components:lastEditedOn")} 20/09/2021</p>
        </div>
        <div>
          <button
            onClick={() => {
              navigate("/settings/editemail");
            }}
          >
            {t("extras:btnEdit")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditEmail;
