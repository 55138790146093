import React, { useState } from "react";
import Cropper from "react-easy-crop";

function ImageCropper({ image, onCropDone, onCropCancel }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(3.456 / 1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "20rem",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "6px",
          bottom: "10px",
          left: "0",
          right: "0",
        }}
      >
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "100%",
              height: "80%",
              backgroundColor: "#fff",
              border: "2px dashed grey",
            },
          }}
        />
      </div>

      <div
        style={{
          paddingTop: "17rem",
          position: "absolute",
          display: "flex",
          columnGap: "10px",
        }}
      >
        <div className="crpBtn" onClick={onCropCancel}>
          Cancel
        </div>

        <div
          className="crpBtn"
          onClick={() => {
            onCropDone(croppedArea);
          }}
        >
          Done
        </div>
      </div>
    </div>
  );
}

export default ImageCropper;
