import { useContext, useState } from "react";

import qs from "qs";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  ThemeProvider,
} from "@mui/material";

import "./DeleteTab.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { commentDialogTheme } from "../../utils/MuiThemes";

function DeleteTab() {
  const { t } = useTranslation(["components", "extras"]);
  const { state, dispatch } = useContext(Context);
  const { currentTabId, tabSettings } = state;
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [showLastModal, setShowLastModal] = useState(false);
  const [startCheckPass, setStartCheckPass] = useState(false);
  const [startDelete, setStartDelete] = useState(false);

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };
  const handleLastModal = () => {
    setShowLastModal(false);
    setShowModal(true);
  };

  const handleModal = () => {
    setShowModal(false);
  };
  const hideAllModal = () => {
    setShowLastModal(false);
    setShowModal(false);
  };

  const handleConfirmPassword = async ({ psswrd }) => {
    const url = "/confpw";
    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({ pass: psswrd }),
      url,
    };
    try {
      setStartCheckPass(true);
      const { data } = await axios(options);
      setStartCheckPass(false);
      if (data.status === false) {
        enqueueSnackbar(data.msg, { variant: "error" });
      } else {
        handleLastModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteTab = async () => {
    setStartDelete(true);
    const { data } = await axios.post(
      "/delc",
      {
        id: `${currentTabId}`,
        type: "t",
      },
      config
    );
    setStartDelete(false);
    if (data?.status === true) {
      enqueueSnackbar(data.msg, { variant: "success" });
      hideAllModal();
      dispatch({ type: "DELETE_TAB", payload: currentTabId });
      dispatch({ type: "SHOW_TAB_PAGE", payload: null });
      dispatch({ type: "SHOW_TAB_PAGE_ID", payload: null });
    } else {
      enqueueSnackbar(data.msg, { variant: "error" });
    }
  };
  return (
    <>
      <div
        className={
          tabSettings?.includes("delete")
            ? "dtContainer"
            : "dtContainer dtContainerCol"
        }
      >
        <div className="dtSubContainer">
          <div
            className="dtIconText"
            onClick={() => {
              setShowLastModal(true);
            }}
          >
            <DeleteIcon className="dtIcon" /> {t("components:deleteTabL")}
          </div>
        </div>

        <ThemeProvider theme={commentDialogTheme}>
          <Dialog
            open={showModal}
            onClose={hideAllModal}
            maxWidth="xs"
            PaperProps={{
              style: {
                boxShadow: "none",
              },
            }}
          >
            <DialogActions>
              <div className="dtModalCont">
                <h4>{t("extras:btnDeleteTab")}</h4>
                <p>{t("components:sureDeleteTab")}</p>
                <p>{t("components:deleteTabWarn")}</p>
                <p>{t("components:noUndo")}</p>
                <div className="btn">
                  <button onClick={handleModal} className="btnCancel">
                    {t("extras:btnCancel")}
                  </button>
                  <button onClick={handleDeleteTab} className="btnExecute">
                    {t("extras:btnDelete")}
                    {startDelete && (
                      <CircularProgress size={15} className="dtBtnProgress" />
                    )}
                  </button>
                </div>
              </div>
            </DialogActions>
          </Dialog>

          <Dialog
            open={showLastModal}
            onClose={hideAllModal}
            maxWidth="xs"
            PaperProps={{
              style: {
                boxShadow: "none",
              },
            }}
          >
            <DialogActions>
              <form
                className="dtForm"
                onSubmit={handleSubmit(handleConfirmPassword)}
              >
                <p>{t("components:descDelTab")}</p>
                <div>
                  <input
                    {...register("psswrd", { required: true })}
                    type="password"
                    placeholder={t("extras:enterPass")}
                    className="dtInput"
                  />
                  <button type="submit" className="dtButton">
                    {t("extras:btnContinue")}
                    {startCheckPass && (
                      <CircularProgress size={15} className="dtBtnProgress" />
                    )}
                  </button>
                </div>
              </form>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
    </>
  );
}

export default DeleteTab;
