import { Upload } from "@mui/icons-material";
import React, { useRef } from "react";

function BannerInput({ onImageSelected }) {
  const inputRef = useRef();

  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };

  const onChooseImg = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <label style={{ fontSize: "12px" }}>
        This image is displayed at the top of your Tab’s page. It’s ideally a
        group photograph or an info-graphic banner in landscape orientation.
        Image preferred ratio (h : w) = 1 : 3456
      </label>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />

      <div
        className="tsLabels"
        style={{
          padding: "5px 8px",
          border: "1px solid #c4c4c4",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "fit-content",
        }}
        onClick={onChooseImg}
      >
        Choose Banner Image <Upload sx={{ fontSize: "20px" }} />
      </div>
    </div>
  );
}

export default BannerInput;
