import { useContext } from "react";

import { useTranslation } from "react-i18next";

import "./LastSeen.scss";
import { Context } from "../../utils/Context";
import Moment from "react-moment";
import { Circle } from "@mui/icons-material";

function LastSeen() {
  const { t } = useTranslation(["components"]);
  const { state } = useContext(Context);
  const { userInfo } = state;
  return (
    <div className="lastSeen">
      {userInfo && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "5px",
            }}
          >
            {t("lastLogin")}:
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "5px",
              }}
            >
              <span>
                <Moment format="DD/MM/YYYY">
                  {localStorage.getItem("lastSeen") !== undefined
                    ? localStorage.getItem("lastSeen")
                    : new Date()}
                </Moment>
              </span>{" "}
              <Circle className="dot" />
              <span>
                <Moment format="HH:mm">
                  {localStorage.getItem("lastSeen") !== undefined
                    ? localStorage.getItem("lastSeen")
                    : new Date()}
                </Moment>
              </span>
            </span>
            <div style={{ marginLeft: "5px" }}>GMT</div>
          </div>
        </>
      )}
    </div>
  );
}

export default LastSeen;
