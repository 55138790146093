import { createContext, useReducer } from "react";

export const TCContext = createContext("");

export const commentState = {
  loadMore: 0,
  remain: true,
  showMore: null,
  commentData: [],
  newComment: null,
  showModal: null,
  open: false,
};

export function commentReducer(state, action) {
  switch (action.type) {
    case "LOAD_MORE":
      const count = action.payload ? action.payload : 3;
      return { ...state, loadMore: state.loadMore + count };
    case "REMAIN":
      return { ...state, remain: action.payload };
    case "SHOW_MORE":
      return { ...state, showMore: action.payload };
    case "SET_COMMENT_DATA":
      if (action.payload !== undefined && action.payload.length !== 0) {
        const incomingComments = action.payload;
        const newComments = incomingComments.filter((cm) =>
          state.commentData.every((cd) => cd.comnt_id !== cm.comnt_id)
        );
        return {
          ...state,
          commentData: [...state.commentData, ...newComments],
        };
      }

    case "UPDATE_COMMENT_DATA":
      const newSibling = action.payload?.newSibling;
      const parentId = action.payload?.parentId;

      const newC = {
        attchmnts: [],
        children: 0,
        comnt_id: newSibling?.comnt_id,
        content: newSibling?.content,
        edited: false,
        likes: 0,
        like_st: 0,
        parent: newSibling?.parent,
        save_st: 0,
        settings: [true, true],
        tab_title: "",
        tab_id: newSibling?.tab_id,
        time: Date.now(),
        usr_id: newSibling?.usr_id,
      };

      if (newSibling?.parent === parentId?.toString()) {
        return {
          ...state,
          commentData: [...state.commentData, newC],
        };
      }

    case "SHOW_MODAL":
      return { ...state, showModal: action.payload };
    case "OPEN_MODAL":
      return { ...state, open: true };
    case "CLOSE_MODAL":
      return { ...state, open: false };
    case "DELETE_COMMENT":
      const fData = state.commentData.filter(
        (filtered) => filtered.comnt_id !== action.payload
      );
      return { ...state, commentData: fData };

    default:
      return state;
  }
}

export function TCContextProvider(props) {
  const [tcState, tcDispatch] = useReducer(commentReducer, commentState);
  const value = { tcState, tcDispatch };
  return (
    <TCContext.Provider value={value}>{props.children}</TCContext.Provider>
  );
}
