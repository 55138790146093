import { useSnackbar } from "notistack";

export const useCopyTextToClipboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const copyTextToClipboard = async (text) => {
    enqueueSnackbar("Link copied", { variant: "success" });
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return navigator.clipboard.copy(text);
    }
  };
  return copyTextToClipboard;
};
