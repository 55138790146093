import { useContext } from "react";
import { Context } from "../../utils/Context";
import AnonymousTabs from "../AnonymousTabs/AnonymousTabs";
import { AnonContextProvider } from "../AnonymousTabs/State";
import PrivateTabs from "../PrivateTabs/PrivateTabs";
import { PriContextProvider } from "../PrivateTabs/State";
import PublicTabs from "../PublicTabs/PublicTabs";
import { PubContextProvider } from "../PublicTabs/State";

const TimeLine = () => {
  const { state } = useContext(Context);
  const { timeLine } = state;
  return (
    <>
      {timeLine === "All Topics" && (
        <AnonContextProvider>
          <AnonymousTabs />
        </AnonContextProvider>
      )}
      {timeLine === "Public Topics" && (
        <PubContextProvider>
          <PublicTabs />
        </PubContextProvider>
      )}

      {timeLine === "Private Topics" && (
        <PriContextProvider>
          <PrivateTabs />
        </PriContextProvider>
      )}
    </>
  );
};

export default TimeLine;
