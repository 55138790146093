import { useContext } from "react";
import MinorPagesLayout from "../../components/MinorPagesLayout/MinorPagesLayout";
import axios from "../../utils/Axios";
import "./confirmation.scss";

import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import ConfirmAccount from "../../components/ConfirmAccount/ConfirmAccount";
import { Context } from "../../utils/Context";

function Confirmation() {
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useContext(Context);
  const { newUserEmail } = state;
  const resendFunc = async () => {
    const { data } = await axios.get(`/acc?newEmail=${newUserEmail}`);

    enqueueSnackbar(data?.msg, {
      variant: data?.status === true ? "success" : "error",
    });
  };
  return (
    <MinorPagesLayout
      title="Confirm your account"
      description="Confirmation TruTab"
    >
      <div className="cmContainer">
        <div>
          <p>Almost done...</p>
          <p>
            <span className="cmSubHeading">Confirm your account</span>
          </p>
          <p>
            A pin has been sent to your email. It is valid for only 10 minutes.
          </p>
          <p>
            Check your email inbox for the confirmation pin to activate your
            account.
          </p>
          <ConfirmAccount />
          <p>
            <span className="cmSmall">
              Remember to check the Junk or Spam folders of your email.
            </span>
          </p>

          <p>
            <span className="cmSmall">
              Didn&apos;t get the email? Wait a minute, then{" "}
              <button onClick={resendFunc}>resend.</button>
            </span>
          </p>
          <p>
            <Link to="/login">Go to Login</Link>
          </p>
        </div>
      </div>
    </MinorPagesLayout>
  );
}

export default Confirmation;
