export const initialState = {
  showSetting: null,
  showComment: null,
  parent: null,
  newComment: null,
  moreComment: false,
  topicData: [],
  posting: false,
  load: 0,
  loading: false,
  open: false,
  showModal: null,
};

export function reducer(state, action) {
  switch (action.type) {
    case "SET_LOAD":
      return { ...state, load: state.load + 3 };
    case "SHOW_SETTING":
      return { ...state, showSetting: action.payload };
    case "SHOW_COMMENT":
      return { ...state, showComment: action.payload };
    case "PARENT":
      return { ...state, parent: action.payload };
    case "NEW_COMMENT":
      const data = action.payload;
      if (data?.status === true) {
        return { ...state, newComment: data?.new_c };
      }
    case "START_LOADING":
      return { ...state, loading: true };
    case "STOP_LOADING":
      return { ...state, loading: false };
    case "POSTING":
      return { ...state, posting: true };
    case "POSTING_DONE":
      return { ...state, posting: false };
    case "UPDATE_COMMENT":
      const pId = action.payload.pId;
      const newCount = action.payload.newCount;
      const toUpdate = state.topicData?.find((td) => td?.comnt_id === pId);

      if (toUpdate?.children !== undefined) {
        if (toUpdate?.children < action.payload?.newCount) {
          state.moreComment = true;
        }
        toUpdate.children = newCount;
        return { ...state };
      }
      return { ...state };

    case "TURN_OFF_MORE":
      return { ...state, moreComment: false };
    case "TURN_ON_MORE":
      return { ...state, moreComment: true };
    case "UPDATE_TOPIC_STATUS":
      const tId = action.payload?.tId;
      const newStatus = action.payload;
      const statusUpdate = state.topicData?.find((td) => td?.comnt_id === tId);
      if (statusUpdate?.settings !== undefined) {
        statusUpdate.settings[0] = newStatus?.status?.public;
        statusUpdate.edited = newStatus?.status?.edited;
        statusUpdate.content = newStatus?.content;
        return { ...state };
      }
      return { ...state };

    case "SET_TOPIC_DATA":
      const incomingTopic = action.payload;
      if (typeof incomingTopic?.filter === "function") {
        return { ...state, topicData: incomingTopic };
      }

    case "SHOW_MODAL":
      return { ...state, showModal: action.payload };
    case "OPEN_MODAL":
      return { ...state, open: true };
    case "CLOSE_MODAL":
      return { ...state, open: false };
    case "DELETE_TOPIC":
      const fData = state.topicData.filter(
        (filtered) => filtered.comnt_id !== action.payload
      );
      return { ...state, topicData: fData };
    default:
      return state;
  }
}
