import "./CreateTab.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function CreateTab() {
  const { t } = useTranslation(["components", "extras"]);
  const navigate = useNavigate();
  // HideTabPage();

  return (
    <div className="ctContainer">
      <div className="ctHeading">{t("createTabs")}</div>
      <div className="ctTextContainer">
        <p className="ctText">{t("tabsHint1")}</p>
        <p className="ctText">{t("tabsHint2")}</p>
      </div>

      <button onClick={() => navigate("/home/createtab")}>
        {t("createNewTab")}
      </button>
    </div>
  );
}

export default CreateTab;
