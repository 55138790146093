import { useContext, useState, useEffect, useRef } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import "./CreateATab.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { Close, Done } from "@mui/icons-material";
import TabPage from "../TabPage/TabPage";
import { CircularProgress, ThemeProvider, Tooltip } from "@mui/material";
import { HideTabPage } from "../../utils/Effects";
import { TooltipTheme } from "../../utils/MuiThemes";
import Divider from "../Divider/Divider";
import { useNavigate } from "react-router-dom";

function CreateATab() {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(false);
  const [tabName, setTabName] = useState("");
  const [debouncedName, setDebouncedName] = useState(tabName);
  const [nameStatus, setNameStatus] = useState("");
  const [formattedName, setFormattedName] = useState("");
  const [inputMembers, setInputMembers] = useState();
  const [fileName, setFileName] = useState("No file chosen");
  const fileRef = useRef();

  const { t } = useTranslation(["components", "extras"]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  let fileReader;

  const { state, dispatch } = useContext(Context);
  const { currentTabName, currentTabId } = state;
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  //Show Tab Page
  const showTabPage = (name, id) => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: name });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: id });
    dispatch({ type: "ON_PAGE", payload: "tab" });
  };

  const removeMember = (member) => {
    const filteredPeople = members.filter((pa) => pa !== member);
    setMembers(filteredPeople);
  };

  const addInputMember = () => {
    const remArr = inputMembers.split(/,\s*|\s+/).filter(Boolean);
    const newMember = remArr.filter((rm) => members.every((mb) => rm !== mb));
    if (remArr[0].length !== 0) {
      setMembers([...members, ...newMember]);
    }
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;
    const remArr = content.split(/,\s*|\s+/).filter(Boolean);
    setMembers(members.concat(remArr));
  };

  const handleFileChosen = (file) => {
    setFileName(file.name);
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const newTabHandler = async ({ tblNm, tblDesc, title }) => {
    const url = "/newtab";
    const formData = new FormData();
    formData.append("title", title);
    formData.append("name", tblNm);
    formData.append("desc", tblDesc);
    formData.append("list", members);
    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: formData,
      url,
    };
    try {
      setStart(true);
      const { data } = await axios(options);
      setStart(false);
      if (data.status === true) {
        reset();
        setTabName("");
        setNameStatus("");
        setMembers([]);
        setFileName("No file chosen");
        enqueueSnackbar(data.msg, { variant: "success" });
        dispatch({ type: "NEW_TAB" });
        showTabPage(data?.generated?.name, data?.tab_id);
      } else {
        enqueueSnackbar(data.msg, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Hide Tab page
  HideTabPage();

  useEffect(() => {
    const timer = setTimeout(() => setTabName(debouncedName), 1000);
    return () => clearTimeout(timer);
  }, [debouncedName]);

  useEffect(() => {
    const handleCheck = async () => {
      if (tabName?.length >= 3) {
        setLoading(true);
        const { data } = await axios.get(`/tabname?q=${tabName}`, config);
        setLoading(false);

        if (data.status && data.paid_account) {
          setFormattedName(data?.generated?.name);
          setNameStatus("Available");
        }
        if ((data.status || !data.status) && !data.paid_account) {
          setFormattedName(`Use: ${data?.generated?.name}`);
          setNameStatus("Free Account");
        }
        if (!data.status && data.paid_account) {
          setFormattedName(data?.msg);
          setNameStatus("Taken");
        }
      } else {
        setNameStatus("");
        setFormattedName("");
      }
    };

    handleCheck();
  }, [tabName]);

  useEffect(() => {
    if (members.length === 0) {
      fileRef.current.value = null;
    }
  }, [members.length]);

  return (
    <>
      {(currentTabName !== null || currentTabId !== null) && <TabPage />}

      <div className="catHead">
        {t("createATab")}
        <span> {t("forYourOrg")}</span>
      </div>

      <div className="catHintContainer">
        <h4>{t("hintParticipant")}</h4>
        <div>
          <p>1. {t("youCanAddParticipants")}</p>
          <p>2. {t("orYouCanUpload")}</p>
          <p>
            3. {t("uponCreation")}{" "}
            <span className="invitationLinks">{t("invitationLinks")}</span>{" "}
            <span>{t("forParticipantsTo")}</span>
          </p>
        </div>
      </div>

      {members.length !== 0 && (
        <div className="membersNo">
          {t("components:totalOf")} {members?.length}{" "}
          {t("components:repFiltered")}
        </div>
      )}
      <div className="modMembersList modMemberList">
        {members?.map((member, index) => {
          return (
            <div key={index}>
              {member}
              <Close className="modIcon" onClick={() => removeMember(member)} />
            </div>
          );
        })}
      </div>

      <div className="catContainer">
        <div style={{ width: "100%" }} className="tsFields">
          <label className="tsLabels">
            {t("components:addParticipantsOp")}
          </label>
          <div className="addListCont">
            <textarea
              placeholder={t("components:addEmails")}
              {...register("moreMembers")}
              onChange={(e) => setInputMembers(e.target.value)}
            />
            <div className="catFile">
              <label htmlFor="participants" className="fileLabel">
                Upload List | {fileName}
              </label>
              <input
                id="participants"
                type="file"
                accept=".txt"
                ref={fileRef}
                onChange={(e) => handleFileChosen(e.target.files[0])}
              />
            </div>
            <div className="btnAddMembers">
              <button type="button" onClick={() => addInputMember()}>
                {t("extras:addOrEdit")}
              </button>
            </div>
          </div>
          <p className="catError">{errors.tblNmbrs ? "Email missing" : ""}</p>
        </div>
        <div className="catDivider">
          <Divider />
        </div>
        <form onSubmit={handleSubmit(newTabHandler)}>
          <div className="tsFields">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="tsLabels">
                {t("components:tabDetails")} <span className="astTs">*</span>
              </label>

              {loading ? (
                <span>
                  <CircularProgress size={10} />
                </span>
              ) : (
                <span>
                  {nameStatus === "Available" ? (
                    <span className="catNameStatus">
                      {t("components:available")}
                      <Done
                        fontSize="30"
                        style={{
                          color: "green",
                          marginLeft: "2px",
                        }}
                      />
                    </span>
                  ) : nameStatus === "Taken" ? (
                    <span className="catNameStatus">
                      {t("components:taken")}
                      <img
                        src="/assets/images/warning.svg"
                        alt=""
                        style={{ marginLeft: "2px" }}
                      />
                    </span>
                  ) : nameStatus === "Free Account" ? (
                    <ThemeProvider theme={TooltipTheme}>
                      <Tooltip
                        arrow
                        title="Free Accounts cannot fully specify unique names for Tabs, unless name starts with an underscore (_)."
                      >
                        <img src="/assets/images/warning.svg" alt="" />
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    ""
                  )}
                </span>
              )}
            </div>

            <input
              placeholder={t("components:catPhName")}
              {...register("tblNm", {
                required: true,
              })}
              onChange={(e) => setDebouncedName(e.target.value)}
            />
            <div className="formattedName">{formattedName}</div>
            <p className="catError">
              {errors.tblNm ? t("components:errTabName") : ""}
            </p>
          </div>
          <div className="tsFields">
            <input
              placeholder={t("tabsDisplayName")}
              {...register("title", {
                required: true,
              })}
            />
            <p className="catError">
              {errors.title ? "Title is required" : ""}
            </p>
          </div>

          <div className="tsFields">
            <textarea
              placeholder={t("components:catPhDesc")}
              {...register("tblDesc", {
                required: true,
              })}
            />
            <p className="catError">
              {errors.tblDesc ? t("components:errTabDesc") : ""}
            </p>
          </div>

          <div className="catBtn">
            <button
              type="button"
              className="catClearBtn"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("extras:btnCancel")}
            </button>

            <button type="submit" className="catSeBtn">
              <span> {t("extras:btnAddTab")} </span>
              {start ? (
                <CircularProgress size={10} className="catBtnProgress" />
              ) : (
                <img src="/assets/images/EmailSend.svg" alt="" />
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateATab;
