import React, { useContext } from "react";
import "./TabSettings.scss";
import { Context } from "../../utils/Context";

const SettingsCard = ({ name, desc, tag, click }) => {
  const { state } = useContext(Context);
  const { tabSettings } = state;
  return (
    <div onClick={click} className="cardContainer">
      <div>
        <div className="cardName">{name}</div>
        <div className="cardDesc">{desc}</div>
      </div>
      <img
        src="/assets/images/forwardArrow.png"
        alt=""
        className={
          tabSettings?.includes(tag) ? "cardArrowRotate" : "cardArrow "
        }
      />
    </div>
  );
};

export default SettingsCard;
