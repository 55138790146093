export const RenderContent = (contentArray, uid, type) => {
  const result = contentArray?.map((content, index) => (
    <div key={index} className="render_cont">
      <span> {content.split("$$")[0]}</span>
      <span>
        {" "}
        {type === "topic" && contentArray?.length - 1 === index && uid}
      </span>
    </div>
  ));

  return result;
};
