export const initialState = {
  tabData: null,
  load: 0,
  descData: null,
  showModal: false,
  errorPage: false,
  followStatus: "",
  showSettings: false,
  showSetTopic: false,
  showTopic: [],
  topicData: [],
  reload: false,
  isTabDirector: false,
  linkData: {},
  forumDetails: {},
  showForumAction: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case "SET_LOAD":
      return { ...state, load: state.load + 3 };
    case "SET_DESC_DATA":
      return { ...state, descData: action.payload };
    case "SET_LINK_DATA":
      return { ...state, linkData: action.payload };
    case "SHOW_MODAL":
      return { ...state, showModal: true };
    case "HIDE_MODAL":
      return { ...state, showModal: false };
    case "SHOW_FORUM_ACTION":
      return { ...state, showForumAction: true };
    case "HIDE_FORUM_ACTION":
      return { ...state, showForumAction: false };
    case "SET_FOLLOW_STATUS":
      return { ...state, followStatus: action.payload };
    case "SET_FORUM_DETAILS":
      return { ...state, forumDetails: action.payload };
    case "SET_SHOW_SETTINGS":
      return { ...state, showSettings: !state.showSettings };
    case "HIDE_SETTINGS":
      return { ...state, showSettings: false };
    case "SET_SHOW_TOPIC":
      return {
        ...state,
        showSetTopic: !state.showSetTopic,
      };

    case "SET_TAB_DIRECTOR":
      return {
        ...state,
        isTabDirector: action.payload,
      };
    case "HIDE_TOPIC":
      return { ...state, showSetTopic: false };
    case "INCLUDE_TOPIC":
      return {
        ...state,
        showTopic: [...state.showTopic, action.payload],
      };
    case "REMOVE_TOPIC":
      const filtered = state.showTopic?.filter((ft) => ft !== action.payload);
      return { ...state, showTopic: filtered };
    case "SET_ERROR_PAGE":
      return { ...state, errorPage: true };
    case "UPDATE_TAB_TOPIC":
      const fData = state.topicData.filter(
        (filtered) => filtered?.comnt_id !== action.payload
      );
      if (state.descData !== null) {
        state.descData.total = state.descData.total
          ? state.descData.total - 1
          : 0;
      }
      return { ...state, topicData: fData };

    case "UPDATE_TAB_PUBLIC_TOPIC":
      const newStatus = action.payload;
      if (!newStatus) {
        state.descData.total_public -= 1;
      } else {
        state.descData.total_public += 1;
      }
      return { ...state };

    case "TAB_SETTINGS_DONE":
      const title = action.payload?.title;
      const name = action.payload?.name;
      const desc = action.payload?.desc;
      const img = action.payload?.img;

      state.descData.tab_desc.title = title;
      state.descData.tab_desc.name = name;
      state.descData.tab_desc.desc = desc;
      state.descData.tab_desc.img = img;
      return {
        ...state,
      };

    case "UPDATE_TOPIC_TITLE":
      const topicId = action.payload?.topicId;
      const newTopic = action.payload?.newTopic;
      const targetTopic = state.topicData?.find(
        (topic) => topic.comnt_id === topicId
      );
      targetTopic.content = newTopic;
      return {
        ...state,
      };
    case "REFRESH":
      return {
        ...initialState,
      };
    case "START_RELOAD":
      return { ...state, reload: true };
    case "STOP_RELOAD":
      return { ...state, reload: false };

    case "SET_TOPIC_DATA":
      if (action.payload !== (undefined || [])) {
        const incomingData = action.payload;
        const newData = incomingData?.filter((id) =>
          state.topicData?.every(
            (td) => td?.comnt_id !== id?.comnt_id || td?.comnt_id === ""
          )
        );
        return {
          ...state,
          topicData: [...state.topicData, ...newData],
        };
      }
    case "ADD_NEW_TOPIC":
      const topicName = action.payload?.name;
      const newTopicId = action.payload?.id;

      const incomingTopic = {
        children: "",
        comnt_id: newTopicId,
        content: topicName,
        edited: false,
        settings: [false, true],
        tab_latest: "",
        time: "",
        ttId: "",
        usr_id: "",
      };
      if (state.descData !== null) {
        state.descData.total = state.descData.total
          ? state.descData.total + 1
          : 1;
      }
      return {
        ...state,
        topicData: [incomingTopic, ...state.topicData],
      };

    case "UPDATE_TOPIC_COMMENT_NO":
      const input = action.payload;
      const target = state.topicData?.find((tr) => tr.comnt_id === input.pId);
      if (target !== undefined) {
        target.children = input.newCount;
      }
      return { ...state };

    default:
      return state;
  }
}
