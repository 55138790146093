import { useState, useContext, useEffect } from "react";
import { useSnackbar } from "notistack";

import "./Icons.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { formatChildren } from "../../utils/TimeFormatter";

export function LikeIcon({ postId, like, lkSt }) {
  const [currentLike, setCurrentLike] = useState(like);
  const [currentStatus, setCurrentStatus] = useState(lkSt);
  const [timeInterval, setTimeInterval] = useState(60000);
  const { state } = useContext(Context);
  const { whichPageShouldOn } = state;

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const fetchLikes = async () => {
    const { data } = await axios.post("/likepost", { id: postId }, config);

    if (data.status === true) {
      setCurrentLike(data?.new_count);
      setCurrentStatus(data?.new_status);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const tkn = localStorage.getItem("tkn");
      const config = {
        headers: {
          authorization: `${tkn}`,
        },
      };
      try {
        const { data } = await axios.get(
          `/ntfx/count/likes?posts=${postId}`,
          config
        );

        if (data?.status) {
          if (!data?.msg[0]?.likes || data?.msg[0]?.likes === currentLike) {
            if (timeInterval <= 3840000) {
              setTimeInterval((prev) => prev * 2);
            } else {
              setTimeInterval(60000);
            }
          } else {
            setCurrentLike(data?.msg[0]?.likes);
            setTimeInterval(60000);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (whichPageShouldOn === "topic") {
      // Fetch every timeInterval
      const intervalId = setInterval(fetchData, timeInterval);

      // Cleanup on component unmount
      return () => clearInterval(intervalId);
    }
  }, [postId, timeInterval]);

  return (
    <div>
      <span className="badgeCont">
        <img
          src={`/assets/images/${
            currentStatus === 1 ? "heart" : "heartGrey"
          }.svg`}
          className="badgeIicon"
          onClick={fetchLikes}
        />
        <span className="badgeContText">
          {currentLike === 0 ? "" : formatChildren(currentLike)}
        </span>
      </span>
    </div>
  );
}

export function FlagIcon({ postId, flagged }) {
  const [flag, setFlag] = useState(flagged);
  const { enqueueSnackbar } = useSnackbar();

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const fetchFlag = async () => {
    const { data } = await axios.get(`/flag?id=${postId}`, config);
    if (data?.status === true) {
      setFlag(data?.not_flagged);
    } else {
      enqueueSnackbar(data?.msg, { variant: "error" });
    }
  };

  return (
    <div>
      <span className="badgeIcon">
        {flag === false ? (
          <img
            src="/assets/images/flag.svg"
            alt=""
            className="activeIconFlag"
            onClick={fetchFlag}
          />
        ) : (
          <img
            src="/assets/images/flagGrey.svg"
            alt=""
            className="activeIconFlag"
            onClick={fetchFlag}
          />
        )}
      </span>
    </div>
  );
}

export function SaveIcon({ postId, saved }) {
  const [save, setSave] = useState(saved);

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };
  const fetchSave = async () => {
    const { data } = await axios.post("/savepost", { postId }, config);
    if (data?.status) {
      setSave(data?.new_status);
    }
  };

  return (
    <div>
      <span className="badgeIcon">
        {save !== 0 ? (
          <img
            src="/assets/images/save.svg"
            alt=""
            className="activeIcon"
            onClick={fetchSave}
          />
        ) : (
          <img
            src="/assets/images/saveGrey.svg"
            alt=""
            className="activeIcon"
            onClick={fetchSave}
          />
        )}
      </span>
    </div>
  );
}

export function SettingsIcon({ usrId, cmntId, parent }) {
  const [parents, setParents] = useState([]);
  const { state, dispatch } = useContext(Context);
  const { userInfo, verified } = state;

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  useEffect(() => {
    const fetchData = () => {
      for (let i = 0; i < verified?.length; i++) {
        if (verified[i]?.is_director === true) {
          setParents((parent) => [...parent, verified[i]?.name]);
        }
      }
    };
    fetchData();
  }, []);

  const handleShowSetting = async () => {
    dispatch({ type: "SET_TOPICID", payload: cmntId });
    dispatch({ type: "START_SETTING_LOADING" });
    const { data } = await axios.get(`/dxr?tab=${parent}`, config);
    dispatch({ type: "IS_DIRECTOR", payload: data?.status });
    dispatch({ type: "STOP_SETTING_LOADING" });
    dispatch({ type: "SHOW_SETTING", payload: cmntId });
  };

  return (
    <>
      {(userInfo?.idd === usrId || parents?.includes(parent)) && (
        <img
          src="/assets/images/setting.svg"
          className="icon"
          onClick={handleShowSetting}
        />
      )}
    </>
  );
}

export function ActionMenuIcon({ userId, parent, children }) {
  const [parents, setParents] = useState([]);
  const { state } = useContext(Context);
  const { userInfo, verified } = state;

  useEffect(() => {
    const fetchData = () => {
      for (let i = 0; i < verified?.length; i++) {
        if (verified[i]?.is_director === true) {
          setParents((parent) => [...parent, verified[i]?.name]);
        }
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {(userInfo?.idd === userId || parents?.includes(parent)) && (
        <>{children}</>
      )}
    </>
  );
}
