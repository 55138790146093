import { useContext } from "react";

import Topics from "../Topics/Topics";
import { PubContext } from "./State";
import { GetTopics } from "../../utils/Effects";

function PublicTabs() {
  const { pubState, pubDispatch } = useContext(PubContext);
  const { load, newTopicStatus } = pubState;

  //Fetch data as component loads
  const topicUrl = `/public?cntr=${load}`;
  GetTopics(load, pubDispatch, topicUrl, newTopicStatus);

  return (
    <Topics title="pub" localState={pubState} localDispatch={pubDispatch} />
  );
}

export default PublicTabs;
