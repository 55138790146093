import "./SeeButtons.scss";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../utils/Base";

function SeeButtons({ flagged, personal, liked, saved, upgrade, addNew }) {
  const navigate = useNavigate();
  const { t } = useTranslation("extras", "components");
  const tkn = localStorage.getItem("tkn");

  return (
    <div className="sbBtn">
      {upgrade === "upgrade" && (
        <button style={{ width: "100%" }}>
          <a
            href={`${baseUrl}/quota?tkn=${tkn}`}
            target="_blank"
            rel="noreferrer"
          >
            {t("btnUQ")}
          </a>
        </button>
      )}
      {flagged === "flag" && (
        <button
          onClick={() => {
            navigate("/home/flagged");
          }}
          style={{ width: "50%" }}
        >
          {t("btnFlagged")}
        </button>
      )}
      {personal === "personal" && (
        <button
          onClick={() => {
            navigate("/home/personal");
          }}
          style={{ width: "50%" }}
        >
          {t("btnPersonal")}
        </button>
      )}
      {liked === "like" && (
        <button
          onClick={() => {
            navigate("/home/liked");
          }}
          style={{ width: "50%" }}
        >
          {t("btnLiked")}
        </button>
      )}

      {saved === "save" && (
        <button
          onClick={() => {
            navigate("/home/saved");
          }}
          style={{ width: "50%" }}
        >
          {t("btnSaved")}
        </button>
      )}
      {addNew === "addNew" && (
        <button
          onClick={() => {
            navigate("/home/createtab");
          }}
          style={{ width: "100%" }}
        >
          {t("components:createNewTab")}
        </button>
      )}
    </div>
  );
}

export default SeeButtons;
