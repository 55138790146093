import { useState, useRef, useContext } from "react";

import qs from "qs";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "../../utils/Context";

import "./Register.scss";
import axios from "../../utils/Axios";
import { CircularProgress } from "@mui/material";

function Register({ showFunc, showRegister }) {
  const { dispatch } = useContext(Context);
  const [show, setShow] = useState(false);
  const [start, setStart] = useState(false);
  const recaptchaRef = useRef(null);
  const { t } = useTranslation(["components", "extras"]);
  const tkn = localStorage.getItem("tkn");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const registerHandler = async ({ name, email, password, confirm }) => {
    const recaptcha = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    const dataSubmit = { name, email, password, confirm, recaptcha };
    const url = "/u";
    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(dataSubmit),
      url,
    };
    if (password !== confirm) {
      enqueueSnackbar("Password and confirm password don't match", {
        variant: "error",
      });
      return;
    }
    try {
      setStart(true);
      const { data } = await axios(options);
      setStart(false);
      if (data?.tkn === "") {
        enqueueSnackbar(data?.msg, { variant: "error" });
        return;
      }
      if (data?.msg?.includes("check email")) {
        localStorage.setItem("tkn", data?.tkn);
        showRegister(false);
        navigate("/confirmation");
        dispatch({ type: "NEW_USER_EMAIL", payload: email });
      } else {
        enqueueSnackbar(data?.msg, {
          variant: data?.status === true ? "success" : "error",
        });
        return;
      }
    } catch (err) {
      setStart(false);

      if (err?.code?.includes("NETWORK")) {
        enqueueSnackbar("No Internet Connection", { variant: "error" });
      }
      console.log(err);
    }
  };

  const showPassword = () => {
    setShow(!show);
  };

  return (
    <div className="regContainer">
      <div className="regWrapper">
        <img
          src="/assets/images/close.svg"
          className="regClose"
          onClick={() => {
            showFunc(false);
          }}
          alt=""
        />
        <p>{t("components:createAcc")}</p>
        <form
          onSubmit={handleSubmit(registerHandler)}
          className="regForm"
          autoComplete="on"
        >
          <p className="regDescription">{t("components:emailSlogan")}</p>
          <div className="regInputContainer">
            <input
              placeholder={t("components:email")}
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              })}
            />
            <img src="/assets/images/Email.svg" alt="" />
          </div>

          {(errors?.email || errors?.email?.type === "pattern") && (
            <p className="regError">
              {errors?.email
                ? errors?.email?.type === "pattern"
                  ? t("components:errEmailNotValid")
                  : t("components:errEmailRequired")
                : ""}
            </p>
          )}

          <div className="regInputContainer">
            <input
              placeholder={t("components:slogan")}
              {...register("name", {
                required: true,
                minLength: 4,
                maxLength: 80,
              })}
            />
          </div>

          {errors?.name && (
            <p className="regError">{t("components:errEnterName")}</p>
          )}
          <p className="regDescription">{t("components:password")}</p>
          <div className="regInputContainer">
            <input
              placeholder={t("components:password")}
              type={show ? "text" : "password"}
              {...register("password", { required: true })}
            />
            <img
              src={
                show ? "/assets/images/Eye.svg" : "/assets/images/Invisible.svg"
              }
              onClick={showPassword}
              alt=""
            />
          </div>
          {errors?.password && (
            <p className="regError">{t("components:errEnterPass")}</p>
          )}

          <div className="regInputContainer">
            <input
              placeholder={t("components:confirmPassI")}
              type={show ? "text" : "password"}
              {...register("confirm", { required: true })}
            />
            <img
              src={
                show ? "/assets/images/Eye.svg" : "/assets/images/Invisible.svg"
              }
              onClick={showPassword}
              alt=""
            />
          </div>
          {errors?.confirm && (
            <p className="regError">{t("components:errConfirmPass")}</p>
          )}
          <div className="ReCAPTCHA">
            <ReCAPTCHA
              sitekey="6LduiQkcAAAAAH7gJkFAfVY4FS0fe3538vjtk-8z"
              ref={recaptchaRef}
            />
          </div>
          <button type="submit" className="regBtn">
            {t("extras:btnSignUp")}
            {start && <CircularProgress size={15} className="btnProgress" />}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Register;
