import { SnackbarProvider } from "notistack";
import { IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";

export const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantSuccess {
    background-color: #dbf9db;
    color: #646;
    font-family: "Open Sans", sans-serif;
    font-size: 0.89rem;
  }
  ,
  &.SnackbarItem-variantError {
    background-color: #f3e5ab;
    color: #646;
    font-family: "Open Sans", sans-serif;
    font-size: 0.89rem;
  }
`;

export const SnackbarCloseButton = ({ snackbarId }) => {
  const { closeSnackbar } = useSnackbar();

  const style = {
    color: "#646",
    height: "15px",
    width: "15px",
  };

  const ibStyle = {
    position: "absolute",
    top: "5px",
    right: "5px",
    height: "20px",
    width: "20px",
  };

  return (
    <IconButton onClick={() => closeSnackbar(snackbarId)} style={ibStyle}>
      <CloseIcon style={style} />
    </IconButton>
  );
};
