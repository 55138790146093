import { useContext } from "react";
import { GetNotifications } from "../../utils/Effects";
import "./Notification.scss";
import { Context } from "../../utils/Context";
import { CircularProgress } from "@mui/material";
const Notification = () => {
  GetNotifications();

  const { state, dispatch } = useContext(Context);
  const { notifications, notificationsLoading, showNotifications } = state;

  //Show Tab Page
  const showTabPage = (notification) => {
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: notification?.tab_id });
    dispatch({
      type: "ON_PAGE",
      payload: "tab",
    });
  };

  //Show Topic Page
  const showTopicPage = (notification) => {
    dispatch({
      type: "SHOW_TOPIC_PAGE",
      payload: {
        comntId: parseInt(notification?.parnt),
        tabId: notification?.tab_id,
      },
    });
    dispatch({
      type: "ON_PAGE",
      payload: "topic",
    });
  };

  return (
    <section
      className={showNotifications ? "ntfContainerShow" : "ntfContainer"}
    >
      {notificationsLoading && (
        <div className="ntfSpinner">
          <CircularProgress size={20} color="inherit" />
        </div>
      )}
      {notifications?.msg?.map((notification, index) => (
        <div
          className="ntf"
          key={index}
          onClick={() =>
            notification?.parnt === "0"
              ? showTabPage(notification)
              : showTopicPage(notification)
          }
        >
          <p className="ntfMessage">{`${notification?.count} ${
            notification?.parnt === "0"
              ? `new ${notification?.count > 1 ? "posts" : "post"} on`
              : `new ${
                  notification?.count > 1 ? "comments" : "comment"
                } on a post on`
          }`}</p>
          <p className="ntfTabName">{notification?.ttitle}</p>
        </div>
      ))}
    </section>
  );
};

export default Notification;
