import { useContext, useState } from "react";
import "./Logout.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LogoutOutlined } from "@mui/icons-material";
import { Modal } from "@mui/material";

function Logout() {
  const { t } = useTranslation(["components", "extras"]);
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const [openLogout, setOpenLogout] = useState(false);

  const accountLogout = async () => {
    await axios.get("/logout/all", config);
    dispatch({ type: "USER_LOGOUT" });
    navigate("/login");
  };

  const logoutDevice = () => {
    dispatch({ type: "USER_LOGOUT" });
    navigate("/login");
  };
  return (
    <div className="logoutContainer">
      <button onClick={() => setOpenLogout(true)}>
        <LogoutOutlined className="logoutIcon" />
        {t("menuLogout")}
      </button>

      <Modal
        open={openLogout}
        onClose={() => setOpenLogout(false)}
        className="logoutModal"
      >
        <div className="logoutBtnContainer">
          <div className="logoutBtnSubContainer">
            <button onClick={logoutDevice}>{t("extras:logoutDevice")}</button>
            <div className="accountLogoutBtnContainer">
              <button onClick={accountLogout}>
                {t("extras:accountLogout")}
              </button>
              <small>{t("extras:logsOutAll")}</small>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Logout;
