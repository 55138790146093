import { useContext, useReducer, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { CircularProgress, IconButton } from "@mui/material";

import "./TabPage.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { reducer, initialState } from "./State";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import HomeLeftBox from "../HomeLeftBox/HomeLeftBox";
import Footer from "../Footer/Footer";
import RightComponent from "../RightComponent/RightComponent";
import defaultImg from "../../publicImages/newTabl.svg";
import { useSnackbar } from "notistack";

function ListParticipation() {
  const newUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { state, dispatch } = useContext(Context);
  const { t } = useTranslation(["components", "extras"]);
  const navigate = useNavigate();
  const { token } = useParams();
  const [lState, lDispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(newUserInfo?.email);
  const [isAdded, setIsAdded] = useState(false);
  const { descData, errorPage, linkData } = lState;
  const { enqueueSnackbar } = useSnackbar();

  //fetchTabDataReload

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  //fetchDescriptionData

  const fetchDescData = async (id) => {
    try {
      const { data } = await axios.get(`/u/t?t=${id}`, config);
      if (data?.status === false) {
        lDispatch({ type: "SET_ERROR_PAGE" });
      } else {
        lDispatch({ type: "SET_DESC_DATA", payload: data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //fetchTabData
  const fetchLinkData = async () => {
    try {
      const { data } = await axios.get(`/tsl/${token}`, config);
      if (data?.status === false) {
        lDispatch({ type: "SET_ERROR_PAGE" });
      } else {
        lDispatch({ type: "SET_LINK_DATA", payload: data });
        if (data?.the_tab?.tab_id !== undefined) {
          fetchDescData(data?.the_tab?.tab_id);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLinkData();
  }, []);

  const addEmail = async (e) => {
    e.preventDefault();
    if (!email) {
      enqueueSnackbar("Email not found!", { variant: "error" });
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.get(`/tsl/${token}?email=${email}`, config);
      setLoading(false);
      if (data?.status) {
        setIsAdded(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      navigate(`/lists/${token}`);
    }
  }, []);

  const { alertLeftColor, openTabImage } = state;
  return (
    <>
      <Layout
        title={`${
          descData?.tab_desc?.title === undefined
            ? ""
            : descData?.tab_desc?.title
        } (@${
          descData?.tab_desc?.name === undefined ? "" : descData?.tab_desc?.name
        }) on TruTab`}
        description="Trutab&aposs home page"
      >
        <div
          className={`left ${alertLeftColor ? " changeColorofLeftPanel" : ""}`}
        >
          <div className="withPadding">
            <HomeLeftBox />
          </div>

          <div className="footer" style={{ position: "absolute", bottom: "0" }}>
            <Footer />
          </div>
        </div>

        <div className="middle listMiddle">
          {errorPage ? (
            <div className="expiredLink">{t("components:linkBroken")}</div>
          ) : (
            <>
              <div className="topicPageNavCont">
                <nav className="topicPageNav" style={{ top: "3.6rem" }}>
                  <div className="topicIconCont">
                    <IconButton
                      className="topicBtn"
                      onClick={() => {
                        dispatch({
                          type: "SHOW_TOPIC_PAGE",
                          payload: { comntId: null, tabId: null },
                        });
                        dispatch({ type: "ON_PAGE", payload: "tab" });
                        navigate("/");
                      }}
                    >
                      <img
                        src="/assets/images/arrowBackBlue.png"
                        alt=""
                        className="tpNavArrowBack"
                      />
                    </IconButton>
                  </div>
                  <div className="topicTitleCont">
                    {" "}
                    <h3 className="topicTitle">
                      {t("components:listAnonPart")}
                    </h3>
                  </div>
                </nav>
              </div>
              <div className="listNotification">
                {t("components:inviteMsg")}
              </div>
              {descData?.tab_desc?.img !== null && (
                <div
                  className="tpBodyImg"
                  style={{
                    width: "fit-content",
                    margin: "auto",
                    position: "relative",
                  }}
                >
                  {descData?.tab_desc?.img === "" ? (
                    <img
                      src={defaultImg}
                      alt=""
                      className="defaultImg listImg"
                    />
                  ) : (
                    <img
                      src={`https://storage.googleapis.com/trutab/imgs/${descData?.tab_desc?.img}`}
                      alt=""
                      className={`listImg ${
                        openTabImage ? "tabImgDisplay" : "tabImg"
                      }`}
                    />
                  )}

                  <div className="listTabData">
                    <div className="listTabFullName">
                      {" "}
                      {descData?.tab_desc?.tab_title}
                    </div>
                    <div className="listTabUniqueName">
                      {" "}
                      @{descData?.tab_desc?.name}
                    </div>
                    <div className="listTabDesc">
                      {" "}
                      {descData?.tab_desc?.desc}
                    </div>
                  </div>
                </div>
              )}
              <div style={{ marginTop: "2rem" }}>
                <div className="inviteName">{linkData?.the_tab?.listName}</div>
                <div className="inviteNote">
                  {t("components:timedToExpire")}
                </div>

                <form className="tabForm" onSubmit={addEmail}>
                  <div className="inviteInputContainer">
                    {linkData?.msg?.includes("Expecting") && (
                      <>
                        <input
                          type="email"
                          className="tsInput"
                          defaultValue={newUserInfo?.email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label className="listInputBottomLabel">
                          {t("components:useSameEmail")}
                        </label>
                      </>
                    )}

                    <div className="tsBtn">
                      <button type="submit">
                        {t("components:addEmail")}
                        {loading && (
                          <CircularProgress size={15} className="btnProgress" />
                        )}
                      </button>
                    </div>
                    {isAdded && (
                      <>
                        <div className="emailAdded">
                          {t("components:emailAdded")}
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </>
          )}
        </div>

        <div className="right">
          <RightComponent />
        </div>
      </Layout>
    </>
  );
}

export default ListParticipation;
