import { useState, useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";

import "./TabsYouFollow.scss";
import axios from "../../utils/Axios";
import Divider from "../Divider/Divider";
import { Context } from "../../utils/Context";
import TabsHeadCard from "../TabsHeadCard/TabsHeadCard";
import SeeButtons from "../SeeButtons/SeeButtons";

function TabsYouFollow() {
  const [followed, setFollowed] = useState([]);
  const [viewLimit, setViewLimit] = useState(4);
  const [moreTab, setMoreTab] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(["components, extras"]);
  const { state, dispatch } = useContext(Context);
  const { followStatus, tabSettings, tabsHeadCard } = state;

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await axios.get("/following", config);
      setLoading(false);
      setFollowed(data?.tabs_fllw);
    };
    fetchData();
  }, [followStatus, tabSettings]);

  const viewAll = () => {
    if (!moreTab) {
      setViewLimit(followed?.length);
      setMoreTab(!moreTab);
    } else {
      setViewLimit(3);
      setMoreTab(!moreTab);
    }
  };

  //Show Tab Page
  const showTabPage = (follow) => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: follow?.name });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: follow?.tab_id });
    dispatch({
      type: "ON_PAGE",
      payload: "tab",
    });
  };

  return (
    <div className="ufContainer">
      <TabsHeadCard
        name={t("components:tabsYouFollow")}
        tag="follow"
        click={() => dispatch({ type: "TABS_HEAD_CARD", payload: "follow" })}
        icon={<img src="/assets/images/follow.svg" alt="" />}
      />
      <section
        className={
          tabsHeadCard?.includes("follow")
            ? "ufTabsHead"
            : "ufTabsHead ufTabsHeadCol"
        }
      >
        {loading ? (
          <p className="ufLoading">{t("components:loading")}</p>
        ) : followed?.length === 0 ? (
          <p className="ufNoTab">{t("components:noFollow")}</p>
        ) : (
          followed?.slice(0, viewLimit).map((follow, index) => {
            return (
              <button
                onClick={() => {
                  showTabPage(follow);
                }}
                className="ufTab"
                key={index}
              >
                <span className="unique">
                  @
                  {follow?.name?.length < 20
                    ? follow?.name
                    : follow?.name?.substring(0, 20) + "..."}
                </span>
                <span className="nonUnique">
                  {follow?.title?.length < 20
                    ? follow?.title
                    : follow?.title?.substring(0, 20) + "..."}
                </span>
              </button>
            );
          })
        )}
        {followed?.length > 4 && (
          <div className="ufButton">
            <button onClick={viewAll}>
              {moreTab ? t("extras:btnVL") : t("extras:btnVA")}
            </button>
          </div>
        )}
        <div style={{ paddingBottom: "5px" }}></div>
      </section>
      <div className="ypTabsNameDivider">
        <Divider />
      </div>
      <SeeButtons liked="like" saved="save" />
      <div className="ypTabsNameDividerT">
        <Divider />
      </div>
    </div>
  );
}

export default TabsYouFollow;
