import { useContext } from "react";

import { Context } from "../../utils/Context";
import { useTranslation } from "react-i18next";

import "./HomeProfile.scss";
import Avatar from "../Avatar/Avatar";
import { GetCreated, GetUserTabs, GetVerified } from "../../utils/Effects";

function HomeProfile({ text }) {
  const { t } = useTranslation(["components"]);
  const { state } = useContext(Context);
  const { userInfo } = state;

  //Get user tabs
  GetUserTabs();
  GetCreated();
  GetVerified();

  return (
    <div className="hpContainer">
      <div className="hpProfile">
        <div className="hpAvatar">
          <Avatar />
        </div>
        <div className="hpDispName">
          <div className="hpPersonal">{t("personal")}</div>
          {userInfo?.name ? (
            <div className="hpUserName">{userInfo?.name}</div>
          ) : (
            <div className="hpUserName">Public</div>
          )}

          {userInfo?.name ? (
            <div className="hpEmail">
              {userInfo ? userInfo?.email : "Public"}
            </div>
          ) : (
            <div className="hpEmail">Public</div>
          )}
        </div>
      </div>
      <div className="hpText">{text && text}</div>
    </div>
  );
}

export default HomeProfile;
