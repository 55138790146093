import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";

import "./ChangePhoto.scss";
import { Context } from "../../utils/Context";
import { Photo } from "../../photo";

function ChangePhoto() {
  const [photoLimit, setPhotoLimit] = useState(8);
  const [morePhoto, setMorePhoto] = useState(false);

  const { t } = useTranslation(["components", "extras"]);

  const { dispatch } = useContext(Context);

  //See more photos
  const seeMorePhotos = () => {
    if (!morePhoto) {
      setPhotoLimit(Photo.length);
      setMorePhoto(!morePhoto);
    } else {
      setPhotoLimit(8);
      setMorePhoto(!morePhoto);
    }
  };

  return (
    <>
      <div className="photoGrid">
        {Photo.slice(0, photoLimit).map((imgUrl) => {
          return (
            <img
              src={imgUrl}
              key={imgUrl}
              alt="Choose"
              onClick={() => {
                dispatch({ type: "SET_DP", payload: imgUrl });
                localStorage.setItem("dispPics", imgUrl);
              }}
            />
          );
        })}
        <div className="photoMore" onClick={seeMorePhotos}>
          {morePhoto ? t("extras:btnSL") : t("extras:btnSM")}
        </div>
      </div>
      <form>
        <div className="photoChoose">
          <div className="photoChoosePhoto">
            <input type="file" id="file" className="photoFile" disabled />
            <label htmlFor="file">
              Upload Photo <img src="/assets/images/Camera.svg" alt="Button" />
            </label>
          </div>
          <button className="photoSave" disabled>
            {t("extras:btnSave")}
          </button>
        </div>
      </form>
    </>
  );
}

export default ChangePhoto;
