import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import "./ConfirmAccount.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { CircularProgress } from "@mui/material";

function ConfirmAccount() {
  const { state, dispatch } = useContext(Context);
  const { newUserEmail, lastVisitedForumToken } = state;
  const { handleSubmit, register } = useForm();
  const [startConfirm, setStartConfirm] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const handleConfirmation = async ({ tempkey }) => {
    try {
      setStartConfirm(true);
      const { data } = await axios.get(
        `/vfup?tempkey=${tempkey}&email=${newUserEmail}`,
        config
      );
      setStartConfirm(false);
      if (data?.status === true) {
        lastVisitedForumToken === null &&
          enqueueSnackbar(data?.msg, { variant: "success" });
        localStorage.setItem("tkn", data?.tkn);
        setTimeout(() => {
          if (lastVisitedForumToken === null) {
            if (
              localStorage.getItem("tipsCount") &&
              JSON.parse(localStorage?.getItem("tipsCount") < 4)
            ) {
              const newCount =
                JSON.parse(localStorage.getItem("tipsCount")) + 1;
              localStorage.setItem("tipsCount", JSON.stringify(newCount));
            }
            if (!localStorage.getItem("tipsCount")) {
              localStorage.setItem("tipsCount", JSON.stringify(1));
            }
            navigate("/home");
            dispatch({ type: "SHOW_TIPS" });
          } else if (lastVisitedForumToken === "forums") {
            navigate("/forums");
          } else {
            navigate(`/forums/${lastVisitedForumToken}`);
          }
        }, 1000);
      } else {
        enqueueSnackbar(data?.msg, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="cfmSuperContainer">
      <div className="cfmContainer">
        <form onSubmit={handleSubmit(handleConfirmation)} className="cfmForm">
          <input
            id="tempkey"
            placeholder="Enter pin"
            {...register("tempkey")}
          />

          <div className="cfmBtn">
            <button type="submit">
              CONFIRM{" "}
              {startConfirm && (
                <CircularProgress size={15} className="btnProgress" />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConfirmAccount;
