import "./ViewSub.scss";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ViewSub() {
  const { t } = useTranslation(["components"]);
  return (
    <div className="vsContainer">
      <Link to={"/settings/subscriptions"}>{t("viewSub")}</Link>
    </div>
  );
}

export default ViewSub;
