import { Modal } from "@mui/material";
import TipsCard from "../TipsCard/TipsCard";
import { tipsData } from "../../utils/TipsData";
import "./Tips.scss";
import { useContext, useEffect } from "react";
import { Context } from "../../utils/Context";

const Tips = () => {
  const { state, dispatch } = useContext(Context);
  const { showTips, tipStep, withinLimit } = state;
  const tipsCount = JSON.parse(localStorage.getItem("tipsCount"));
  const WithinLimit = () => {
    if (!tipsCount) {
      dispatch({ type: "SET_WITHIN_LIMIT", payload: true });
    }
    if (tipsCount && tipsCount < 4) {
      dispatch({ type: "SET_WITHIN_LIMIT", payload: true });
    }
    if (tipsCount && tipsCount > 4) {
      dispatch({ type: "SET_WITHIN_LIMIT", payload: false });
    }
  };

  useEffect(() => {
    WithinLimit();
  }, [tipsCount]);

  return (
    <Modal open={showTips && withinLimit}>
      <div
        style={{
          position: "absolute",
          top: tipStep === 0 ? "132px" : tipStep === 1 ? "255px" : "302px",
        }}
        className="tipsCont"
      >
        <TipsCard data={tipsData} />
      </div>
    </Modal>
  );
};

export default Tips;
