import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./SelectLang.scss";
function SelectLang() {
  const { t } = useTranslation(["components", "extras"]);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng") === null) {
      i18next.changeLanguage(navigator.language);
    }
  }, []);

  useEffect(() => {
    const options = document.querySelectorAll(".option");
    options.forEach((option) => {
      if (localStorage.getItem("i18nextLng") === option.value) {
        option.setAttribute("selected", "true");
      }
    });
  }, []);

  const handleSelectLng = (e) => {
    i18next.changeLanguage(e.target.value);
  };
  return (
    <div className="slContainer">
      <select onChange={handleSelectLng} className="select">
        <option value="en" className="option">
          🇬🇧 {t("extras:en")}
        </option>
        <option value="fr" className="option">
          🇫🇷 {t("extras:fr")}
        </option>
        <option value="it" className="option">
          🇮🇹 {t("extras:it")}
        </option>
        <option value="de" className="option">
          🇩🇪 {t("extras:de")}
        </option>
        <option value="zh" className="option">
          🇯🇵 {t("extras:zh")}
        </option>
        <option value="es" className="option">
          🇪🇸 {t("extras:es")}
        </option>
        <option value="nl" className="option">
          🇳🇱 {t("extras:nl")}
        </option>
        <option value="hi" className="option">
          🇮🇳 {t("extras:hi")}
        </option>
        <option value="ko" className="option">
          🇰🇷 {t("extras:ko")}
        </option>
        <option value="pt" className="option">
          🇵🇹 {t("extras:pt")}
        </option>
        <option value="ind" className="option">
          🇮🇩 {t("extras:ind")}
        </option>
      </select>
    </div>
  );
}

export default SelectLang;
