import { useReducer } from "react";
import { GetSubscription } from "../../utils/Effects";
import { initialState, reducer } from "./State";

import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./SubscriptionHistory.scss";
import Moment from "react-moment";

function SubscriptionHistory() {
  const [lState, lDispatch] = useReducer(reducer, initialState);
  const { load, subData, loading, loadText } = lState;
  const { t } = useTranslation(["components"]);
  GetSubscription(lDispatch, load);

  const allSubs = subData?.map((subs, index) => {
    return (
      <tr key={index}>
        <td dataLabel={t("startDate")}>
          <Moment format="ddd, DD MMM YYYY HH:mm">{subs[0]}</Moment> GMT
        </td>
        <td dataLabel={t("jQuota") + " / M"}>{subs[1]}</td>
        <td dataLabel={t("jQuota") + " / D"}>{subs[2]}</td>
        <td dataLabel={t("endDate")}>
          <Moment format="ddd, DD MMM YYYY HH:mm">{subs[3]}</Moment> GMT
        </td>
      </tr>
    );
  });
  return (
    <div className="shContainer">
      <div className="shHeading">{t("subHistory")}</div>
      {subData?.length === 0 && !loading && (
        <div className="shNoSub">{t("noSubMade")}</div>
      )}
      {subData?.length !== 0 && (
        <table className="shTable">
          <thead>
            <th>{t("startDate")}</th>
            <th>{t("jQuota")}/M</th>
            <th>{t("jQuota")}/D</th>
            <th>{t("endDate")}</th>
          </thead>
          <tbody>{allSubs}</tbody>
        </table>
      )}
      {loading && (
        <div className="shLoading">
          <CircularProgress size={25} color="inherit" />
        </div>
      )}
      {subData?.length !== 0 && (
        <div className="shSeeOlderSub">
          <button
            onClick={() => {
              lDispatch({ type: "SET_LOAD" });
            }}
          >
            {loadText === "See older subscriptions"
              ? t("seeOlderSub")
              : loadText === "...Loading"
              ? t("loading")
              : t("noSub")}
          </button>
        </div>
      )}
    </div>
  );
}

export default SubscriptionHistory;
