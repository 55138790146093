import { useContext, useState, useEffect } from "react";

import "./Avatar.scss";
import { Context } from "../../utils/Context";

function Avatar() {
  const { state } = useContext(Context);
  const { dispPics } = state;

  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    setAvatar(
      localStorage.getItem("dispPics")
        ? `${localStorage.getItem("dispPics")}`
        : "/assets/images/photo1.png"
    );
  }, [dispPics]);

  return (
    <div className="avaContainer">
      <img src={avatar} className="avaImage" alt="display pics" />
      <img
        src="/assets/images/badgeoffline.svg"
        className="avaBadge"
        alt="badge"
      />
    </div>
  );
}

export default Avatar;
