import { Circle } from "@mui/icons-material";
import {
  addTimeToTimestamp,
  FormatTime,
  getTimeDifference,
} from "../../utils/TimeFormatter";
import PollPage from "../PollPage/PollPage";
import { GetPollData } from "../../utils/Effects";
import { useTranslation } from "react-i18next";

export const RenderPolls = ({ topicData }) => {
  const { t } = useTranslation(["components, extras"]);
  const contentArray = topicData?.content?.split(/\r?\n/);
  const { pollData, setPollData } = GetPollData(topicData?.comnt_id);

  return (
    <>
      <div>
        {contentArray.map((content, index) => {
          return (
            <div key={index} style={{ width: "100%" }}>
              <PollPage
                data={content}
                topicData={topicData}
                pollData={pollData}
                setPollData={setPollData}
              />
            </div>
          );
        })}
      </div>
      {/* third block  */}
      <div className="thirdBlock">
        <div>
          <div className="status">
            <div>
              {topicData?.settings[0] === true
                ? t("components:publicT")
                : t("components:privateT")}
              <Circle className="dot" />
            </div>
            <div>
              {topicData?.edited === true && (
                <>
                  {t("components:edited")} <Circle className="dot" />
                </>
              )}
            </div>
            <div>{FormatTime(topicData?.time)}</div>{" "}
          </div>
        </div>

        {topicData?.poll_dur && (
          <div className="post_vote">
            <div>
              {pollData?.total} {pollData?.total <= 1 ? "vote" : "votes"}
            </div>
            <Circle className="dot" />
            <p>
              <span>
                {getTimeDifference(
                  addTimeToTimestamp(topicData?.time, topicData?.poll_dur)
                ) || "Calculating..."}
              </span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};
