import { useContext, useEffect, useRef, useState } from "react";
import { baseUrl } from "../../utils/Base";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useScrollToSection } from "../../utils/Effects";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { useCopyTextToClipboard } from "../../utils/Copy";
import Divider from "../Divider/Divider";

const CreateInvite = ({ controlState }) => {
  const [ivWarning, setIvWarning] = useState(false);
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const [hrs, setHrs] = useState(0);
  const [min, setMin] = useState(0);
  const [inviteName, setInviteName] = useState(null);
  const [inviteLink, setInviteLink] = useState(null);
  const { t } = useTranslation(["components", "extras"]);
  const { enqueueSnackbar } = useSnackbar();
  const copyTextToClipboard = useCopyTextToClipboard();

  const { state } = useContext(Context);
  const { currentTabName } = state;
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };
  const inviteNameRef = useRef();
  const createInviteRef = useRef();

  const createInviteLink = async (e) => {
    e.preventDefault();
    const hour = (hrs * 60 * 60 + min * 60) / 3600;

    if (ivWarning === true) {
      enqueueSnackbar("Wrong input!", { variant: "error" });
      return;
    } else {
      try {
        setIsCreatingLink(true);
        setInviteLink(null);
        const { data } = await axios.get(
          `/tslCreate?hours=${hour}&tab=${currentTabName}&list=${inviteName}`,
          config
        );
        setIsCreatingLink(false);

        if (data?.status) {
          setInviteLink(data?.link);
          setHrs(0);
          setMin(0);
          setInviteName(null);
          inviteNameRef.current.value = null;
          if (inviteLink !== null) {
            await axios.get(`/trutab_qrcode?q=${inviteLink}&f=png`);
          }
        } else {
          enqueueSnackbar(data?.msg, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (hrs * 60 * 60 + min * 60 > 86400) {
      setIvWarning(true);
    } else {
      setIvWarning(false);
    }
  }, [hrs, min]);

  useEffect(() => {
    if (!controlState) {
      setInviteLink(null);
    }
  }, [controlState]);

  useScrollToSection(createInviteRef, "start");

  return (
    <form
      onSubmit={createInviteLink}
      className="createForum"
      ref={createInviteRef}
    >
      <Divider />
      <div className="createForumTitle">{t("components:createInviteLink")}</div>

      <div className="createForumBodyHead">
        {t("components:createLinkForTab")}
      </div>
      <div className="tsFields">
        <label className="tsLabels">{t("components:invitationTimed")}</label>
        <div className="tsTimeContainer">
          <div>{t("components:selectDuration")}</div>
          <div className="timeContainer">
            <div>
              <input
                type="text"
                value={hrs}
                maxLength={2}
                onChange={(e) =>
                  isNaN(e.target.value) ||
                  e.target.value > 24 ||
                  e.target.value < 0
                    ? console.log("wrong input")
                    : setHrs(Number(e.target.value))
                }
              />
              <div className="timeLabel">HRS</div>
            </div>

            <div className="timeColon">:</div>

            <div className="timeSubContainer">
              <input
                type="text"
                value={min}
                maxLength={2}
                onChange={(e) =>
                  isNaN(e.target.value) ||
                  e.target.value > 60 ||
                  e.target.value < 0
                    ? console.log("wrong input")
                    : setMin(Number(e.target.value))
                }
              />
              <div className="timeLabel">MIN</div>
            </div>
          </div>{" "}
          <div className="maxTime">Max: 24 hrs</div>
        </div>
      </div>
      {ivWarning && <p className="tfWarning">{t("components:timeExceeded")}</p>}
      <div className="tsBtn">
        <button type="submit">
          {t("components:createLink")}
          {isCreatingLink && (
            <CircularProgress size={15} className="btnProgress" />
          )}
        </button>
      </div>

      {inviteLink !== null && (
        <>
          <div className="inviteLink">
            <span>{inviteLink?.substring(0, 62)}... </span>{" "}
            <div className="copyContainer">
              <img
                src="/assets/images/copyPurple.svg"
                alt=""
                onClick={() => copyTextToClipboard(inviteLink)}
              />
            </div>
          </div>
          <div className="qrCont">
            <img
              src={`${baseUrl}/trutab_qrcode?q=${inviteLink}&f=png`}
              alt=""
            />
          </div>
        </>
      )}
    </form>
  );
};

export default CreateInvite;
