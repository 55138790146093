function Divider({ width, color }) {
  return (
    <div
      style={{
        height: "0.5px",
        width: width,
        backgroundColor: color ? color : "#d9d9d9",
        margin: "0 auto",
      }}
    ></div>
  );
}

export default Divider;
