import React, { useContext } from "react";
import "./TabsHeadCard.scss";
import { Context } from "../../utils/Context";

const TabsHeadCard = ({ name, tag, click, icon }) => {
  const { state } = useContext(Context);
  const { tabsHeadCard } = state;
  return (
    <div onClick={click} className="tabsHeadCont">
      <div className="tabsHeadName">
        {icon ? icon : null}
        {name}
      </div>

      <img
        src="/assets/images/forwardArrow.png"
        alt=""
        className={
          tabsHeadCard?.includes(tag) ? "cardArrowRotate" : "cardArrow "
        }
      />
    </div>
  );
};

export default TabsHeadCard;
