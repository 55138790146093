import SearchResult from "../SearchResult/SearchResult";
import SelectLang from "../SelectLang/SelectLang";
import LastSeen from "../LastSeen/LastSeen";
import Notification from "../Notification/Notification";

function RightComponent() {
  return (
    <div>
      <SelectLang />
      <SearchResult />
      <LastSeen />
      <Notification />
    </div>
  );
}

export default RightComponent;
