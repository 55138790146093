import { useContext } from "react";

import Topics from "../Topics/Topics";
import { SvContext } from "./State";
import { GetTopics } from "../../utils/Effects";

function Saved() {
  const { svState, svDispatch } = useContext(SvContext);
  const { load, newTopicStatus } = svState;

  //Fetch data as component loads
  const topicUrl = `/saved?indx=${load}`;
  GetTopics(load, svDispatch, topicUrl, newTopicStatus);

  return <Topics title="sv" localState={svState} localDispatch={svDispatch} />;
}

export default Saved;
