import { useContext } from "react";

import Topics from "../Topics/Topics";
import { PerContext } from "./State";
import { GetTopics } from "../../utils/Effects";

function PersonalTopics() {
  const { perState, perDispatch } = useContext(PerContext);
  const { load, newTopicStatus } = perState;

  //Fetch data as component loads
  const topicUrl = `/person?cntr=${load}`;
  GetTopics(load, perDispatch, topicUrl, newTopicStatus);

  return (
    <Topics title="per" localState={perState} localDispatch={perDispatch} />
  );
}

export default PersonalTopics;
