import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import "./settings.scss";
import Changephoto from "../../components/ChangePhoto/ChangePhoto";
import Divider from "../../components/Divider/Divider";
import Footer from "../../components/Footer/Footer";
import HomeLeftBox from "../../components/HomeLeftBox/HomeLeftBox";
import HomeProfile from "../../components/HomeProfile/HomeProfile";
import TabsYouControl from "../../components/TabsYouControl/TabsYouControl";
import QueuedTabs from "../../components/QueuedTabs/QueuedTabs";
import TabsYouPaticipate from "../../components/TabsYouPaticipate/TabsYouPaticipate";
import TabsYouFollow from "../../components/TabsYouFollow/TabsYouFollow";
import Layout from "../../components/Layout/Layout";
import RightComponent from "../../components/RightComponent/RightComponent";
import TabPage from "../../components/TabPage/TabPage";
import { Context } from "../../utils/Context";
import { CheckUserInfo, HideTabPage } from "../../utils/Effects";
import TopicPage from "../../components/TopicPage/TopicPage";

function Settings() {
  const { state, dispatch } = useContext(Context);
  const {
    currentTabName,
    currentTabId,
    currentTopicId,
    currentTopicTabId,
    page,
    alertLeftColor,
  } = state;
  const { t } = useTranslation(["components", "extras"]);

  useEffect(() => {
    dispatch({ type: "ERROR_PAGE_ON", payload: false });
  }, []);

  //Check if user is logged in
  CheckUserInfo();

  //Hide Tab page
  HideTabPage();

  return (
    <Layout
      title={t("components:menuSettings")}
      description="Trutab settings page"
    >
      <div
        className={`left ${alertLeftColor ? " changeColorofLeftPanel" : ""}`}
      >
        {page === "tab" ? (
          <>
            <div className="withPadding">
              <HomeProfile />
              <Divider />
              <TabsYouControl />
              <QueuedTabs />
              <TabsYouPaticipate />
              <TabsYouFollow />
            </div>
            <div className="footer">
              <Footer />
            </div>
          </>
        ) : (
          <>
            <div className="withPadding">
              <HomeProfile text={t("components:profileDesc")} />
              <div className="changephoto">
                <div>{t("components:changePhoto")}</div>

                <Changephoto />
              </div>
              <div className="divider">
                <Divider />
              </div>
              <div className="stayintouch">
                <HomeLeftBox />
              </div>
            </div>
            <div className="footer">
              <Footer />
            </div>
          </>
        )}
      </div>

      <div className="middle">
        {(currentTabName !== null || currentTabId !== null) && <TabPage />}
        {currentTopicId !== null && currentTopicTabId !== null && <TopicPage />}
        <p className="setTitle">Settings</p>
        <Outlet />
      </div>
      <div className="right">
        <RightComponent />
      </div>
    </Layout>
  );
}

export default Settings;
