import "./HomeLeftBox.scss";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Context } from "../../utils/Context";

function HomeLeftBox() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(["components", "extras"]);
  const { dispatch } = useContext(Context);

  const handleNavigate = () => {
    if (location.pathname?.split("/")[1] === "forums") {
      if (location.pathname?.split("/")[2] === undefined) {
        dispatch({
          type: "SET_LAST_VISITED_FORUM_TOKEN",
          payload: "forums",
        });
      } else {
        dispatch({
          type: "SET_LAST_VISITED_FORUM_TOKEN",
          payload: location.pathname?.split("/")[2],
        });
      }
    }

    location.pathname?.split("/")[1] === "public" ||
      location.pathname?.split("/")[1] === "tabs" ||
      (location.pathname?.split("/")[1] === "forums" && navigate("/"));
  };

  return (
    <div className="leftBox2">
      <div
        className={
          location.pathname?.split("/")[1] === "public" ||
          location.pathname?.split("/")[1] === "tabs"
            ? "leftBox2HeadPublic"
            : "leftBox2Head"
        }
      >
        {location.pathname?.split("/")[1] === "public" ||
        location.pathname?.split("/")[1] === "tabs"
          ? "Sign-up or Login to participate anonymously"
          : t("components:hearFrom")}
      </div>
      <p
        className={
          location.pathname?.split("/")[1] === "public" ||
          location.pathname?.split("/")[1] === "tabs"
            ? "leftBox2TextPublic"
            : "leftBox2Text"
        }
      >
        {location.pathname?.split("/")[1] === "public" ||
        location.pathname?.split("/")[1] === "tabs"
          ? "With TruTab you and your group can have anonymous conversation, and can get public feedback via anonymous forums."
          : t("components:shareFeedback")}
      </p>
      <button onClick={handleNavigate}>
        {location.pathname?.split("/")[1] === "public" ||
        location.pathname?.split("/")[1] === "tabs" ||
        location.pathname?.split("/")[1] === "forums"
          ? "Sign-up/Login"
          : t("extras:btnJoin")}{" "}
        <img src="/assets/images/forwardbrand.svg" alt="" />
      </button>
    </div>
  );
}

export const HomeLeftBoxTop = () => {
  const location = useLocation();
  const { t } = useTranslation(["components", "extras"]);
  return (
    <div className="leftBox2">
      <div
        className={
          location.pathname?.split("/")[1] === "public" ||
          location.pathname?.split("/")[1] === "tabs"
            ? "leftBox2HeadPublic"
            : "leftBox2Head"
        }
      >
        Anonymous Group Chats
      </div>
      <p
        className={
          location.pathname?.split("/")[1] === "public" ||
          location.pathname?.split("/")[1] === "tabs"
            ? "leftBox2TextPublic"
            : "leftBox2Text"
        }
      >
        For users in groups and organizations to get honest feedback from
        themselves without knowing who said what!
      </p>
    </div>
  );
};

export default HomeLeftBox;
