import { useContext } from "react";
import { Context } from "../../utils/Context";
import { CircularProgress } from "@mui/material";
import Divider from "../Divider/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import "./SearchPostResult.scss";

const SearchPostResult = () => {
  const { dispatch, state } = useContext(Context);
  const { searchPostTab, searchPostTabResult, searchPostProcess } = state;
  const navigate = useNavigate();
  const location = useLocation();

  const showTopicPage = (result) => {
    dispatch({
      type: "SHOW_TOPIC_PAGE",
      payload: {
        comntId: parseInt(result?.comnt_id),
        tabId: result?.tab_id,
      },
    });
    dispatch({
      type: "ON_PAGE",
      payload: "topic",
    });
  };

  const handleOnClickSearchPostTabResult = (result) => {
    dispatch({
      type: "SET_PREVIOUS_PAGE",
      payload: location.pathname.split("/")[1],
    });
    location.pathname.split("/")[1] === "tabs" ||
    location.pathname.split("/")[1] === "topics" ||
    location.pathname.split("/")[1] === "posts" ||
    location.pathname.split("/")[1] === "lists" ||
    location.pathname.split("/")[1] === "forums"
      ? navigate(`/posts/${result?.tab_id}`)
      : showTopicPage(result);
  };

  return (
    <div className="sprContainer">
      {(searchPostTab?.length > 0 || searchPostProcess) && (
        <>
          <div>
            {searchPostProcess ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <CircularProgress size={20} />
              </div>
            ) : (
              searchPostTabResult &&
              !searchPostProcess && (
                <p className="sprTitle">
                  {searchPostTabResult.length === 0 && !searchPostProcess
                    ? "No result found"
                    : "Search Results"}
                </p>
              )
            )}
          </div>
          <div
            className="sprSearchResult"
            style={{ opacity: searchPostTabResult?.length === 0 ? 0 : 1 }}
          >
            {searchPostTabResult?.map((result) => {
              return (
                <div className="sprResultCont" key={result}>
                  <button
                    onClick={() => handleOnClickSearchPostTabResult(result)}
                  >
                    {result?.content?.length >= 40
                      ? result?.content?.slice(0, 40) + "..."
                      : result?.content}
                    <br />
                  </button>
                  <Divider />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default SearchPostResult;
