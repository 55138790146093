import { useContext } from "react";

import Topics from "../Topics/Topics";
import { LkContext } from "./State";
import { GetTopics } from "../../utils/Effects";

function Liked() {
  const { lkState, lkDispatch } = useContext(LkContext);
  const { load, newTopicStatus } = lkState;

  //Fetch data as component loads
  const topicUrl = `/liked?indx=${load}`;
  GetTopics(load, lkDispatch, topicUrl, newTopicStatus);

  return <Topics title="lk" localState={lkState} localDispatch={lkDispatch} />;
}

export default Liked;
