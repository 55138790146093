export const initialState = {
  subData: [],
  offset: null,
  load: 0,
  loading: false,
  loadText: "See older subscriptions",
};

export function reducer(state, action) {
  switch (action.type) {
    case "SET_LOAD":
      if (state.offset !== null || undefined) {
        return { ...state, load: state.offset };
      }

    case "START_LOADING":
      return { ...state, loading: true, loadText: "...Loading" };

    case "STOP_LOADING":
      return { ...state, loading: false, loadText: "See older subscriptions" };
    case "SET_SUB_DATA":
      const incomingSubData = action.payload.allSubs;
      const newOffset = action.payload.offset;
      const newSubData = incomingSubData?.filter((tt) =>
        state.subData?.every((td) => td[0] !== tt[0])
      );
      return {
        ...state,
        subData: [...state.subData, ...newSubData],
        offset: newOffset,
      };
    case "NO_MORE_SUB":
      return {
        ...state,
        loading: false,
        loadText: "No more subscription",
      };
    default:
      return state;
  }
}
