import { useContext, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import "./ChangeEmail.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function ChangeEmail() {
  const [startChangeEmail, setStartChangeEmail] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(["components", "extras"]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { state } = useContext(Context);
  const { userInfo } = state;

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  //Submit new email
  const changeEmailHandler = async ({ ce }) => {
    try {
      setStartChangeEmail(true);
      const { data } = await axios.get(`/change?ce=${ce}`, config);
      setStartChangeEmail(false);
      if (data.status === true) {
        enqueueSnackbar(data.msg, { variant: "success" });
        localStorage.setItem("tkn", data?.tkn);
        navigate("/settings/confirmotp");
      } else {
        enqueueSnackbar(data.msg, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="ceContainer">
      <form onSubmit={handleSubmit(changeEmailHandler)} className="ceForm">
        <label htmlFor="ce">{t("components:newEmail")}</label>
        <input
          type="email"
          defaultValue={userInfo?.email}
          id="ce"
          {...register("ce", {
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          })}
        />
        <p className="ceError">
          {errors.ce
            ? errors.ce.type === "pattern"
              ? "Email is not valid"
              : "Email is required"
            : ""}
        </p>
        <p className="ceWarning">{t("components:ceWarning")}</p>
        <div className="ceBtn">
          <button type="submit">
            {t("extras:btnSave")}
            {startChangeEmail && (
              <CircularProgress size={15} className="btnProgress" />
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangeEmail;
