import { useState } from "react";

import qs from "qs";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import "./ChangeProfile.scss";
import axios from "../../utils/Axios";
import { CircularProgress } from "@mui/material";

function ChangeProfile() {
  const [startChanges, setStartChanges] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation(["components", "extras"]);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const tkn = localStorage.getItem("tkn");

  const submitHandler = async (data) => {
    const url = "/settings";
    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(data),
      url,
    };
    closeSnackbar();
    try {
      setStartChanges(true);
      const { data } = await axios(options);
      setStartChanges(false);
      enqueueSnackbar(data.msg, {
        variant: data.status === true ? "success" : "error",
      });
      if (data?.status === true) {
        localStorage.setItem("tkn", data?.tkn);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="cpContainer">
      <form onSubmit={handleSubmit(submitHandler)} className="cpForm">
        <div>
          <label htmlFor="settsSlug">{t("components:newSlug")}</label>
          <input id="settsSlug" {...register("settsSlug", {})} />
          <p className="cpError">
            {errors.settsSlug ? "Enter new slogan" : ""}
          </p>
        </div>

        <div>
          <label htmlFor="settsNwPw">{t("components:newPass")}</label>
          <input type="password" id="settsNwPw" {...register("settsNwPw")} />
        </div>

        <div>
          <label htmlFor="settsNwPwCnf">{t("components:confirmPass")}</label>
          <input
            type="password"
            id="settsNwPwCnf"
            {...register("settsNwPwCnf")}
          />
        </div>

        <div style={{ display: "flex", width: "100%", columnGap: "8px" }}>
          <div style={{ width: "100%" }}>
            <p>
              <label htmlFor="settsPassChk">
                {t("components:currentPass")}
                <span className="ast">*</span>
              </label>
            </p>
            <input
              type="password"
              id="settsPassChk"
              {...register("settsPassChk", {
                required: true,
              })}
            />
            <p className="cpError">
              {errors.settsPassChk ? "Enter current password" : ""}
            </p>
          </div>

          <div className="cpBtn">
            <button type="submit">
              {t("extras:btnSaveChanges")}
              {startChanges && (
                <CircularProgress size={15} className="btnProgress" />
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ChangeProfile;
