import { useState, useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

import "./Public.scss";
import Footer from "../../components/Footer/Footer";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading/Loading";
import RightComponent from "../../components/RightComponent/RightComponent";
import { LoadingPage, HideTabPage } from "../../utils/Effects";
import { Context } from "../../utils/Context";

import HomeLeftBox from "../../components/HomeLeftBox/HomeLeftBox";
import TabPage from "../../components/TabPage/TabPage";
import TopicPage from "../../components/TopicPage/TopicPage";

export default function Public() {
  const { t } = useTranslation(["components", "extras"]);
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(Context);
  const {
    currentTabName,
    currentTabId,
    currentTopicId,
    currentTopicTabId,
    alertLeftColor,
  } = state;

  useEffect(() => {
    dispatch({ type: "ERROR_PAGE_ON", payload: false });
  }, []);

  //Show loading page
  LoadingPage(setLoading);

  //Hide Tab page
  HideTabPage();

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Layout title="Public View" description="Trutab&aposs Public View">
          <div
            className={`left ${
              alertLeftColor ? " changeColorofLeftPanel" : ""
            }`}
          >
            <div className="withPadding">
              <HomeLeftBox />
            </div>

            <div
              className="footer"
              style={{ position: "absolute", bottom: "0" }}
            >
              <Footer />
            </div>
          </div>

          <div className="middle">
            <p className="fresherMessage">
              <p>{t("components:welcomePub")}</p>
              <p>{t("components:advicePub")}</p>
            </p>

            {(currentTabName !== null || currentTabId !== null) && <TabPage />}
            {currentTopicId !== null && currentTopicTabId !== null && (
              <TopicPage />
            )}
          </div>

          <div className="right">
            <RightComponent />
          </div>
        </Layout>
      )}
    </>
  );
}
