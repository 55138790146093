import MainRoutes from "./MainRoutes";
import CacheBuster from "react-cache-buster";
import { ThreeDots } from "react-loader-spinner";
import version from "../package.json";

function App() {
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={
        <div style={{ padding: "15px" }}>
          <ThreeDots
            height="50"
            width="50"
            radius="9"
            color="#572e91"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      }
      metaFileDirectory={"."}
    >
      <MainRoutes />
    </CacheBuster>
  );
}

export default App;
