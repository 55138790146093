import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Footer.scss";
import Divider from "../Divider/Divider";
import { baseUrl } from "../../utils/Base";
import { useRef, useState } from "react";
import { useClickOut } from "../../utils/Effects";

function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  const { t } = useTranslation(["components"]);
  const location = useLocation();
  const [showMore, setShowMore] = useState(false);
  const [showMoreAbsolute, setShowMoreAbsolute] = useState(false);

  const moreAbsoluteRef = useRef(null);
  const moreAbsoluteRefPanel = useRef(null);

  const moreMobileRef = useRef(null);
  const moreMobileRefPanel = useRef(null);

  useClickOut({
    onState: showMoreAbsolute,
    mainRef: moreAbsoluteRefPanel,
    subRef: moreAbsoluteRef,
    setFunc: setShowMoreAbsolute,
  });

  useClickOut({
    onState: showMore,
    mainRef: moreMobileRefPanel,
    subRef: moreMobileRef,
    setFunc: setShowMore,
  });

  return (
    <div className="ftContainer">
      <div className="ftSubContainer">
        <li>
          <a href={`${baseUrl}/login`}>{t("createAccount")}</a>
        </li>
        <li>
          <Link to={"/public"}>{t("publicView")}</Link>
        </li>

        <li>
          <a href={`${baseUrl}/about`}>{t("footerAbout")}</a>
        </li>
        {location.pathname.split("/")[1] !==
          ("home" || "settings" || "public" || "forums") && (
          <>
            <section className="desktopOnly">
              <li>
                <a href={`${baseUrl}/pricing`}>{t("pricing")}</a>
              </li>
              <li>
                <a href={`${baseUrl}/policy`}>{t("footerPolicy")}</a>
              </li>
              <li>
                <a href={`${baseUrl}/feedback`}>{t("footerContact")}</a>
              </li>
              <li>
                <a href={`${baseUrl}/help`}>{t("footerHelp")}</a>
              </li>
            </section>
            <div className="mobileMoreContainer">
              <li
                className="mobileOnly"
                onClick={() => setShowMore(!showMore)}
                ref={moreMobileRef}
              >
                <span>{t("more")}</span>
              </li>
              <section
                className={showMore ? "moreMobileEx" : "moreMobile"}
                ref={moreMobileRefPanel}
              >
                <li>
                  <a href={`${baseUrl}/pricing`}>{t("pricing")}</a>
                </li>
                <li>
                  <a href={`${baseUrl}/policy`}>{t("footerPolicy")}</a>
                </li>
                <li>
                  <a href={`${baseUrl}/feedback`}>{t("footerContact")}</a>
                </li>
                <li>
                  <a href={`${baseUrl}/help`}>{t("footerHelp")}</a>
                </li>
              </section>
            </div>
          </>
        )}
        {location.pathname.split("/")[1] ===
          ("home" || "settings" || "public" || "forums") && (
          <div className="mobileMoreContainer">
            <li
              onClick={() => setShowMoreAbsolute(!showMoreAbsolute)}
              className={
                location.pathname.split("/")[1] ===
                ("home" || "settings" || "public" || "forums")
                  ? "both"
                  : "desktopOnly"
              }
              ref={moreAbsoluteRef}
            >
              <span>{t("more")}</span>
            </li>
            <section
              className={showMoreAbsolute ? "moreAbsoluteEx" : "moreAbsolute"}
              ref={moreAbsoluteRefPanel}
            >
              <li>
                <a href={`${baseUrl}/pricing`}>{t("pricing")}</a>
              </li>
              <li>
                <a href={`${baseUrl}/policy`}>{t("footerPolicy")}</a>
              </li>
              <li>
                <a href={`${baseUrl}/feedback`}>{t("footerContact")}</a>
              </li>
              <li>
                <a href={`${baseUrl}/help`}>{t("footerHelp")}</a>
              </li>
            </section>
          </div>
        )}
      </div>

      <Divider />
      <div className="copywrite">&copy; {year} TruTab Sys. Inc</div>
    </div>
  );
}

export default Footer;
