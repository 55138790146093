import { createContext, useReducer } from "react";

export const DefaultForumContext = createContext("");

export const initialState = {
  showSetting: null,
  showComment: null,
  showModal: false,
  parent: null,
  newComment: null,
  topicData: [],
  posting: false,
  offset: null,
  load: 0,
  loading: false,
  open: false,
  forumDetails: {},
  descData: null,
  followStatus: "",
  errorPage: false,
  showForumAction: false,
  isTabDirector: false,
  showSetTopic: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case "SET_LOAD":
      if (state.offset !== null || undefined) {
        return { ...state, load: state.offset };
      }

    case "SHOW_SETTING":
      return { ...state, showSetting: action.payload };
    case "SHOW_COMMENT":
      return { ...state, showComment: action.payload };
    case "SHOW_MODAL":
      return { ...state, showModal: true };
    case "HIDE_MODAL":
      return { ...state, showModal: false };
    case "SHOW_FORUM_ACTION":
      return { ...state, showForumAction: true };
    case "HIDE_FORUM_ACTION":
      return { ...state, showForumAction: false };
    case "PARENT":
      return { ...state, parent: action.payload };
    case "NEW_COMMENT":
      const data = action.payload;
      if (data?.status === true) {
        return { ...state, newComment: data?.new_c };
      }
    case "SET_TAB_DIRECTOR":
      return {
        ...state,
        isTabDirector: action.payload,
      };
    case "POSTING":
      return { ...state, posting: true };
    case "POSTING_DONE":
      return { ...state, posting: false };
    case "UPDATE_COMMENT":
      const pId = action.payload.pId;
      const newCount = action.payload.newCount;
      const toUpdate = state.topicData?.find((td) => td?.comnt_id === pId);
      if (toUpdate?.children !== undefined) {
        toUpdate.children = newCount;
        return { ...state };
      }
      return { ...state };
    case "UPDATE_TOPIC_STATUS":
      const tId = action.payload?.tId;
      const newStatus = action.payload;
      const statusUpdate = state.topicData?.find((td) => td?.comnt_id === tId);
      if (statusUpdate?.settings !== undefined) {
        statusUpdate.settings[0] = newStatus?.status?.public;
        statusUpdate.edited = newStatus?.status?.edited;
        statusUpdate.content = newStatus?.content;
        if (!newStatus?.status?.public) {
          state.descData.total_public -= 1;
        } else {
          state.descData.total_public += 1;
        }
        return { ...state };
      }
      return { ...state };

    case "ADD_NEW_TOPIC":
      const newTopicRaw = action.payload;
      const newTopic = { ...newTopicRaw, time: new Date() };
      console.log(action.payload);
      return {
        ...state,
        topicData: [newTopic, ...state.topicData],
      };

    case "SET_TOPIC_DATA":
      const incomingTopics = action.payload.topics;
      const newOffset = action.payload.offset;
      const newTopics = incomingTopics?.filter((tt) =>
        state.topicData.every((td) => td?.comnt_id !== tt?.comnt_id)
      );
      if (newTopics !== undefined) {
        return {
          ...state,
          topicData: [...state.topicData, ...newTopics].sort((a, b) => {
            return new Date(b?.time) - new Date(a?.time);
          }),
          offset: newOffset,
        };
      }
    case "SET_SHOW_TOPIC":
      return {
        ...state,
        showSetTopic: !state.showSetTopic,
      };
    case "HIDE_TOPIC":
      return { ...state, showSetTopic: false };
    case "SET_FORUM_DETAILS":
      return { ...state, forumDetails: action.payload };
    case "SET_DESC_DATA":
      return { ...state, descData: action.payload };
    case "SET_FOLLOW_STATUS":
      return { ...state, followStatus: action.payload };
    case "SET_ERROR_PAGE":
      return { ...state, errorPage: true };
    case "SHOW_MODAL":
      return { ...state, showModal: action.payload };
    case "OPEN_MODAL":
      return { ...state, open: true };
    case "CLOSE_MODAL":
      return { ...state, open: false };
    case "SET_FORUM_DETAILS":
      return { ...state, forumDetails: action.payload };
    case "SET_SHOW_SETTINGS":
      return { ...state, showSettings: !state.showSettings };
    case "DELETE_TOPIC":
      const fData = state.topicData.filter(
        (filtered) => filtered.comnt_id !== action.payload
      );
      return { ...state, topicData: fData };

    case "TAB_SETTINGS_DONE":
      const fName = action.payload.fName;
      const cName = action.payload.cName;

      const targetTopic = state.topicData.filter(
        (topic) => topic.parent === fName
      );
      if (targetTopic !== undefined) {
        targetTopic.every((topic) => (topic.parent = cName));
        return {
          ...state,
        };
      }

    default:
      return state;
  }
}

export function DefaultForumContextProvider(props) {
  const [defaultForumState, defaultForumDispatch] = useReducer(
    reducer,
    initialState
  );
  const value = { defaultForumState, defaultForumDispatch };
  return (
    <DefaultForumContext.Provider value={value}>
      {props.children}
    </DefaultForumContext.Provider>
  );
}
