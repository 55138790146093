var listUID = {};
const convertSliced = (inpx, agrx) => {
  let tmpInt = Math.abs(parseInt(inpx) - agrx) + 65;
  if (tmpInt > 90) {
    tmpInt += 7;
  }
  if (tmpInt > 122) {
    tmpInt = (tmpInt % 122) + 48;
  }
  let chrxCode = String.fromCharCode(tmpInt);
  return chrxCode;
};
export const UidGenerator = (timeCmnt, usrId) => {
  const date = new Date();
  let tmpStrx = "B" + usrId;
  if (listUID[tmpStrx] === undefined && typeof timeCmnt.slice === "function") {
    let hrs = date.getHours();
    let mns = date.getMinutes();
    let scs = date.getSeconds();

    let tmpTimeX = timeCmnt?.slice(17, 19);
    let tmpTimeY = timeCmnt?.slice(20, 22);
    let tmpTimeZ = timeCmnt?.slice(23, 25);

    let tmpX = convertSliced(tmpTimeX, hrs);
    let tmpY = convertSliced(tmpTimeY, mns);
    let tmpZ = convertSliced(tmpTimeZ, scs);

    let tmpPX = tmpX + tmpY + tmpZ;
    listUID[tmpStrx] = tmpPX;
    return tmpPX;
  } else {
    return listUID[tmpStrx];
  }
};
